import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Image, Dropdown, Container, Col } from "react-bootstrap";
import Brand from "./Brand";
import { AppRoutes } from "../../utils/appRoutes";
import { AppContext } from "../../utils/components/AppContext";
import { FaCog, FaSignOutAlt } from "react-icons/fa";
import { AppConstants } from "../../utils/appConstants";
import { appConfigs } from "../../configs";

function TopNavbar({ getRequest, bkgColor, toggleSidebar }) {
  const [bgColor, setBgColor] = useState("bg-transparent");
  const { contextState, updateContextState } = useContext(AppContext);

  const employee = contextState.employee || {};
  const organization = contextState.organization || {};

  if (contextState.isMobileApp) {
    return <div />;
  }

  return (
    <Navbar
      expand={"lg"}
      className={`fixed-to ${bkgColor} d-flex justify-content-between align-items-center ps-lg-5 ms-lg-4 pe-lg-3`}
    >
      {/* Left: Navbar Toggle */}
      <div>
        <Navbar.Toggle
          aria-controls="navbar-nav"
          className={"border-0"}
          onClick={(e) => {
            e.preventDefault();
            toggleSidebar();
          }}
        />
      </div>

      {/* Center: Logo */}
      <div className="d-flex justify-content-center ms-3 ms-lg-0">
        <Image
          src={appConfigs.primaryLogo}
          alt={`${appConfigs.appName} logo`}
          height="35"
          width="35"
          className="align-self-center"
        />
      </div>

      {/* Right: Profile Dropdown */}
      <div>
        <Dropdown align="end" className={"border-0"}>
          <Dropdown.Toggle
            id="dropdown-basic"
            className={"bg-transparent border-0"}
          >
            <Image
              src={employee.profile_image}
              alt="Profile"
              height="30"
              width="30"
              className="rounded-circle"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                getRequest("/user/signout");
              }}
              className="text-danger"
            >
              <FaSignOutAlt /> Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Navbar>
  );
}

export default TopNavbar;
