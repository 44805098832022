import React, { useContext } from "react";
import {
  AddItemButton,
  ConditionalView,
  StatusBadge,
} from "../../../utils/components";
import { updateObjectState, useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { Button, Card, Row } from "react-bootstrap";
import PayrollSettingsForm from "../../Forms/Payroll/PayrollSettingsForm";
import { RiUserSettingsLine } from "react-icons/ri";
import InfoCard from "../../../utils/components/InfoCard";
import { formatDate } from "../../../utils/dateTime";
import ApproversInfoTable from "../Shared/ApproversInfoTable";
import PayrollApproversForm from "../../Forms/Payroll/PayrollApproversForm";
import { FaUserCheck } from "react-icons/fa";
import { PiBookOpenTextBold } from "react-icons/pi";

function PayrollSettings({
  settings,
  setSettings,
  permissions,
  putRequest,
  patchRequest,
  configs,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);
  const hasSettings = Object.keys(settings).length > 0;

  return (
    <div>
      {permissions.MANAGE_PAYROLL && hasSettings && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"Update Settings"}
            onClick={() => {
              updateContextState({
                formData: { ...settings },
              });
              showOffCanvas({
                title: `Edit Settings`,
                subtitle: `Changes you make here will take effect in the next pay period`,
                component: (
                  <PayrollSettingsForm
                    configs={configs}
                    isEdit={true}
                    onSubmit={(updatedData) => {
                      patchRequest(
                        "/payroll/settings",
                        loaderCallback,
                        (response) => {
                          setSettings(response.settings);
                        },
                        updatedData
                      );
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      )}

      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title>Payroll Settings</Card.Title>
          <Card.Subtitle as={"p"} className={"mt-3"}>
            Effortlessly manage your organization's payroll. You can easily
            customize crucial payroll elements like work hours, payment
            frequency, and approver requirements. Align your payroll system with
            your organizational needs for accurate and streamlined payroll
            management.
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <ConditionalView
            condition={hasSettings}
            trueView={
              <div className={"pt-3"}>
                <div className={"d-flex justify-content-between"}>
                  <InfoCard
                    header={"First Pay PeriodStart Date"}
                    body={formatDate(settings.first_pay_period_start_date)}
                  />
                  <InfoCard
                    header={"First Payment Date"}
                    body={formatDate(settings.payments_start_date)}
                  />
                  <InfoCard
                    header={"Status"}
                    body={
                      <StatusBadge
                        status={settings.is_active ? "Active" : "Inactive"}
                      />
                    }
                  />
                </div>

                <Row className={"mt-4"}>
                  <InfoCard
                    header={"Workday Hours"}
                    body={settings.total_workday_hours}
                  />
                  <InfoCard
                    header={"Workweek Hours"}
                    body={settings.total_workweek_hours}
                  />
                  <InfoCard
                    header={"Payment Frequency"}
                    body={settings.payment_frequency}
                  />
                  <InfoCard
                    header={"Minimum Approvers"}
                    body={settings.minimum_approvers}
                  />
                </Row>
              </div>
            }
            falseView={
              <div className={"my-5 text-center"}>
                <span>
                  <PiBookOpenTextBold size={150} />
                  <br />
                </span>
                <h4 className={"my-5"}>
                  You have not set up your organization's payroll yet. Click the
                  button below to get started.
                </h4>
                <Button
                  variant={"primary"}
                  onClick={() => {
                    updateContextState({
                      formData: { ...settings },
                    });
                    showOffCanvas({
                      title: `Set Up Payroll`,
                      subtitle: `Set up payroll for your organization`,
                      component: (
                        <PayrollSettingsForm
                          configs={configs}
                          onSubmit={(updatedData) => {
                            putRequest(
                              "/payroll/settings",
                              loaderCallback,
                              (response) => {
                                setSettings(response.settings);
                              },
                              updatedData
                            );
                          }}
                        />
                      ),
                    });
                  }}
                >
                  Set Up Payroll
                </Button>
              </div>
            }
          />
        </Card.Body>
      </Card>

      {hasSettings && (
        <Card className={"mt-5"}>
          <Card.Header className={"py-4"}>
            <div className={"d-flex justify-content-between"}>
              <Card.Title>Payroll Approvers</Card.Title>
              <AddItemButton
                title={"Add Approver"}
                onClick={() => {
                  updateContextState({
                    formData: {},
                  });
                  showOffCanvas({
                    title: `Add a Payroll Approver`,
                    subtitle: `Add a new payroll approver to your organization`,
                    component: (
                      <PayrollApproversForm
                        onSubmit={(updateData) => {
                          putRequest(
                            "/payroll/approvers",
                            loaderCallback,
                            (response) => {
                              updateObjectState(
                                setSettings,
                                response.payroll_settings
                              );
                            },
                            updateData,
                            false
                          );
                        }}
                      />
                    ),
                  });
                }}
              />
            </div>
            <Card.Subtitle as={"p"} className={"mt-3"}>
              Add the people who will be responsible for approving payroll for
              the organization.
            </Card.Subtitle>
          </Card.Header>
          <Card.Body>
            <ConditionalView
              condition={settings?.approvers?.length > 0}
              trueView={
                <ApproversInfoTable
                  approvers={settings.approvers}
                  onEditClick={(approver) => {
                    updateContextState({
                      formData: approver,
                    });
                    showOffCanvas({
                      title: `Update Payroll Approver`,
                      subtitle: `Update payroll approver for your organization`,
                      component: (
                        <PayrollApproversForm
                          isEdit={true}
                          onSubmit={(updateData) => {
                            patchRequest(
                              "/payroll/approvers",
                              loaderCallback,
                              (response) => {
                                updateObjectState(
                                  setSettings,
                                  response.payroll_settings
                                );
                              },
                              updateData
                            );
                          }}
                        />
                      ),
                    });
                  }}
                />
              }
              falseView={
                <div className={"my-5 text-center"}>
                  <span>
                    <FaUserCheck size={100} />
                    <br />
                  </span>
                  <p className={"my-5"}>
                    You have not set up your organization's payroll approvers
                    yet. Click the "Add Approvers" button above to get started.
                  </p>
                </div>
              }
            />
          </Card.Body>
        </Card>
      )}
    </div>
  );
}

export default PayrollSettings;
