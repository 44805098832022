import React, { useContext, useEffect, useState } from "react";
import { AddItemButton } from "../../../utils/components";
import {
  toTitleCase,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { Button, Card, Row, Table } from "react-bootstrap";
import { StaffPermissions } from "../../../enums/staffEnums";
import GenericForm from "../../Forms/Shared/GenericForm";
import { StaffFormsConfigs } from "../../Forms/FormsConfigs/StaffFormsConfigs";
import { OffCanvasContext } from "../../../utils/components/OffCanvasContext";
import { FormFieldTypes } from "../../FormFields";
import InfoCard from "../../../utils/components/InfoCard";
import { dateFormats, formatDate } from "../../../utils/dateTime";
import PDFViewer from "../Shared/PDFViewer";
import { FaEdit, FaUpload } from "react-icons/fa";

function StaffOrgSubscriptionView({
  subscriptions,
  setSubscriptions,
  organization,
  putRequest,
  patchREquest,
  loaderCallback,
  configs,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { closeOffCanvas } = useContext(OffCanvasContext);

  const { contextState, updateContextState, hasStaffPermission } =
    useContext(AppContext);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [subscriptionItemData, setSubscriptionItemData] = useState({});
  const { showAppModal, closeModal } = useAppModal();
  const [showLocalOffCanvas, setShowLocalOffCanvas] = useState(false);

  // TODO: Implement this on the AppOffCanvasLocal
  const showSubItemForm = (defaultData, subData) => {
    const formConfigs = StaffFormsConfigs.subscriptionItem(
      configs.sub_item_statuses,
      configs.product_bundles,
      <div>
        <h5 className={"mb-3"}>Bundle Products:</h5>
        {Object.entries(configs?.product_bundles).map(([key, values], i) => {
          return (
            <div key={key}>
              <strong>{key}</strong>
              <small>{values?.join(", ")}</small>
            </div>
          );
        })}
      </div>,
      true
    );

    updateContextState({
      formData: {
        ...defaultData,
        added_seats: "0",
        bundles: "Starter",
      },
    });
    showOffCanvas({
      title: `Add Subscription Item`,
      subtitle: `Add a new subscription item to the ${organization.name} organization`,
      component: (
        <>
          <GenericForm
            formConfigs={formConfigs}
            completeButtonTitle={"Add Subscription Item"}
            useGlobalState={true}
            updateGlobalStateOnStateChange={true}
            onSubmit={(formData) => {
              const submitData = {
                ...subData,
                subscription_item: formData,
              };
              putRequest(
                `/staff/organization/${organization.id}/subscription`,
                loaderCallback,
                (response) => {
                  setSubscriptions([response.subscription, ...subscriptions]);
                },
                submitData
              );
            }}
          />
        </>
      ),
    });
  };

  const fileUpload = (vals) => {
    updateContextState({
      formData: { file_type: vals.fileKey },
    });

    showOffCanvas({
      title: `Add / Update the ${vals.fileKey} file`,
      component: (
        <GenericForm
          useFormData={true}
          formConfigs={[
            {
              valueKey: "pdf_file",
              type: FormFieldTypes.PDF,
              maxFileMb: 5,
            },
          ]}
          onSubmit={(formData) => {
            putRequest(
              `/staff/organization-subscription-item/${vals.subItemId}/document`,
              loaderCallback,
              (response) => {
                const subIndex = vals.subIndex;
                const subItemIndex = vals.subItemIndex;
                const newSubscriptions = [...subscriptions];
                newSubscriptions[subIndex].items[subItemIndex] =
                  response.subscription_item;
                setSubscriptions(newSubscriptions);
              },
              formData,
              true
            );
          }}
        />
      ),
    });
  };

  const addOrUpdateSubItem = (vals) => {
    const formConfigs = StaffFormsConfigs.subscriptionItem(
      configs.sub_item_statuses,
      configs.product_bundles,
      <div>
        <h5 className={"mb-3"}>Bundle Products:</h5>
        {Object.entries(configs?.product_bundles).map(([key, values], i) => {
          return (
            <div key={key} className={"mt-2"}>
              <strong>{key}</strong>
              <small>{values?.join(", ")}</small>
            </div>
          );
        })}
      </div>,
      false
    );

    updateContextState({
      formData: { ...vals, added_seats: "0" },
    });
    showOffCanvas({
      title: `Add Subscription Item`,
      subtitle: `Add a new subscription item to the ${organization.name} organization`,
      component: (
        <>
          <GenericForm
            formConfigs={formConfigs}
            onSubmit={(data) => {
              const endpoint = vals.isEdit
                ? `/staff/organization-subscription-item/${vals.subItemId}`
                : `/staff/organization-subscription/${vals.subId}/item`;
              putRequest(
                endpoint,
                loaderCallback,
                (response) => {
                  if (vals.isEdit) {
                    const subIndex = vals.subIndex;
                    const subItemIndex = vals.subItemIndex;
                    const newSubscriptions = [...subscriptions];
                    newSubscriptions[subIndex].items[subItemIndex] =
                      response.subscription_item;
                    setSubscriptions(newSubscriptions);
                  } else {
                    const newSubscriptions = [...subscriptions];
                    newSubscriptions[vals.subIndex].items = [
                      response.subscription_item,
                      ...newSubscriptions[vals.subIndex].items,
                    ];
                  }
                },
                data
              );
            }}
          />
        </>
      ),
    });
  };

  return (
    <div>
      {hasStaffPermission(
        StaffPermissions.MANAGE_ORGANIZATIONS_SUBSCRIPTIONS
      ) && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Subscription"}
            onClick={() => {
              updateContextState({
                formData: { ...subscriptionData },
              });
              showOffCanvas({
                title: `Add Subscription`,
                subtitle: `Add a new subscription to the ${organization.name} organization`,
                component: (
                  <>
                    <GenericForm
                      formConfigs={StaffFormsConfigs.subscription}
                      completeButtonTitle={"Next > Add Subscription Item"}
                      updateGlobasStateOnSubmit={false}
                      onSubmit={(data) => {
                        closeOffCanvas();
                        setTimeout(() => {
                          showSubItemForm(
                            {
                              start_date: data.start_date,
                              end_date: data.end_date,
                              ...subscriptionItemData,
                            },
                            { ...data }
                          );
                        }, 750);
                      }}
                    />
                  </>
                ),
              });
            }}
          />
        </div>
      )}

      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title>Organization Subscriptions</Card.Title>
          <Card.Subtitle as={"p"} className={"mt-3"}>
            View, add or update the subscriptions for this organization based on
            your permissions
          </Card.Subtitle>
        </Card.Header>
      </Card>

      {subscriptions.map((subscription, subIndex) => {
        return (
          <Card className={"mt-4"} key={subscription.id}>
            <Card.Header className={"py-4"}>
              <Card.Title>Subscription {subIndex + 1}</Card.Title>
              <Card.Subtitle as={"p"} className={"mt-3"}>
                {subscription.description}
              </Card.Subtitle>
            </Card.Header>
            <Card.Body>
              <Row>
                <InfoCard
                  lg={4}
                  header={"Start Date"}
                  body={
                    formatDate(
                      subscription?.start_date,
                      dateFormats.shortDateTime24Hr
                    ) || "N/A"
                  }
                />
                <InfoCard
                  lg={4}
                  header={"End Date"}
                  body={
                    formatDate(
                      subscription?.end_date,
                      dateFormats.shortDateTime24Hr
                    ) || "N/A"
                  }
                />
                <InfoCard
                  lg={4}
                  header={"Seats Bought"}
                  body={subscription?.available_seats || "N/A"}
                />
              </Row>

              <h5 className={"mt-5 mb-3 text-center"}>Subscription Items</h5>
              <AddItemButton
                title={"new Subscription Item"}
                onClick={() => {
                  addOrUpdateSubItem({
                    subId: subscription.id,
                    subIndex: subIndex,
                    isEdit: false,
                  });
                }}
              />

              <Table striped bordered responsive hover className={"mt-4"}>
                <thead>
                  <tr className={"text-center fs-6 fw-bold"}>
                    <th>Invoice #</th>
                    <th>Amounts</th>
                    <th>Paid On</th>
                    <th>Valid Dates</th>
                    <th>Bundles</th>
                    <th>Status</th>
                    <th>Files</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {subscription.items.map((item, subItemIndex) => {
                    return (
                      <tr key={item.id} className={"text-center"}>
                        <td>{item.invoice_number}</td>
                        <td>
                          {[
                            "normal_price",
                            "discount_amount",
                            "amount_paid",
                          ].map((key, i) => {
                            return (
                              <div key={i} className={"mb-2"}>
                                <strong>
                                  {item.currency} {item[key]}
                                </strong>
                                <br />
                                <small>{toTitleCase(key)}</small>
                              </div>
                            );
                          })}
                        </td>
                        <td>{formatDate(item.payment_date)}</td>
                        <td>
                          {formatDate(item.start_date)}
                          <br /> to <br />
                          {formatDate(item.end_date)}
                          <br />
                          <br />
                          Starts at 00:00:00 and ends at 00:00:00 on selected
                          dates
                        </td>
                        <td>
                          {Object.entries(item.bundles).map(
                            ([bundle, products], i) => {
                              return (
                                <div key={i} className={"mt-3"}>
                                  <strong>{bundle}</strong>
                                  <br />
                                  <small>{products?.join(", ")}</small>
                                </div>
                              );
                            }
                          )}
                        </td>
                        <td>{item.status}</td>
                        <td>
                          <div className={""}>
                            {Object.entries(configs?.sub_item_doctypes)?.map(
                              ([fileKey, values], fileIndex) => {
                                const file = item[values.filename];
                                return (
                                  <Card
                                    body={true}
                                    key={`${fileIndex}-${fileKey}-${item.id}`}
                                    className={"mb-2"}
                                  >
                                    <strong>{fileKey}</strong>
                                    {file && (
                                      <div>
                                        <PDFViewer pdfUrl={file} />
                                        <Button
                                          variant={"link"}
                                          onClick={() => {
                                            fileUpload({
                                              subItemId: item.id,
                                              subIndex: subIndex,
                                              subItemIndex: subItemIndex,
                                              fileKey: fileKey,
                                            });
                                          }}
                                        >
                                          <FaEdit /> Change
                                        </Button>
                                      </div>
                                    )}
                                    {!file && (
                                      <div className={"text-center"}>
                                        <Button
                                          variant={"link"}
                                          onClick={() => {
                                            fileUpload({
                                              subItemId: item.id,
                                              subIndex: subIndex,
                                              subItemIndex: subItemIndex,
                                              fileKey: fileKey,
                                            });
                                          }}
                                        >
                                          <FaUpload className={"me-2"} />
                                          Upload PDF
                                        </Button>
                                      </div>
                                    )}
                                  </Card>
                                );
                              }
                            )}
                          </div>
                        </td>
                        <td>
                          {hasStaffPermission(
                            StaffPermissions.MANAGE_ORGANIZATIONS_SUBSCRIPTIONS
                          ) && (
                            <Button
                              variant={"link"}
                              onClick={() => {
                                addOrUpdateSubItem({
                                  subId: subscription.id,
                                  subItemId: item.id,
                                  subIndex: subIndex,
                                  subItemIndex: subItemIndex,
                                  isEdit: true,
                                  ...item,
                                  bundles: Object.keys(item.bundles)[0],
                                });
                              }}
                            >
                              <FaEdit /> Edit
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
}

export default StaffOrgSubscriptionView;
