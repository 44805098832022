import React, { useContext, useState } from "react";
import { Button, Form, Card, Alert } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import EntitySelection from "../EntitySelection";
import { formatDateStrings, getTodayFormFormat } from "../../../utils/dateTime";
import { isEmpty } from "../../../utils/functions";

function ScheduleForm({ onSubmit, configs }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );
  const [requiredFields, setRequiredFields] = useState([
    "shift_id",
    "entity_type",
  ]);
  const [selectedShift, setSelectedShift] = useState(null);
  const [errors, setErrors] = useState({});

  const todaysDate = getTodayFormFormat();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    if (formState.disable) {
      return false;
    }
    return requiredFields.every((field) => !isEmpty(formState[field]));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>

      <Form.Group controlId="shiftId" className={"mb-4"}>
        <Form.Label>
          Select Shift<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          as="select"
          name="shift_id"
          value={formState.shift_id || ""}
          onChange={(event) => {
            handleInputChange(event);
            setSelectedShift(
              configs.shifts.find((shift) => shift.id === event.target.value)
            );
          }}
        >
          <option value="">Select Shift</option>
          {configs.shifts.map((shift) => (
            <option key={shift.id} value={shift.id}>
              {shift.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      {selectedShift && (
        <Card body={true} className={"mb-4"}>
          Days:
          <br />
          {selectedShift.active_days_full.join(", ")}
        </Card>
      )}
      <Form.Group controlId="branchDescription" className={"mb-4"}>
        <Form.Label>Schedule Note / Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Alert variant={"info"}>
        All the days within the date range you select below that match the days
        in the shift days above will be added to the schedule. For example, if
        Monday is listed above, all the Monday's in this date range will have
        the shift assigned to employees.
      </Alert>
      <Form.Group controlId="startDate" className={"mb-4"}>
        <Form.Label>
          Start Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="start_date"
          value={formState.start_date || ""}
          min={getTodayFormFormat()}
          onChange={(e) => {
            const value = e.target.value;
            let isValueValid = false;
            if (value && formState.end_date && value > formState.end_date) {
              setErrors({
                ...errors,
                start_date: "Start date cannot be greater than end date",
              });
            } else if (value && value < todaysDate) {
              setErrors({
                ...errors,
                start_date: "Start date cannot be in the past",
              });
            } else {
              setErrors({
                ...errors,
                start_date: null,
              });
              isValueValid = true;
            }

            if (isValueValid) {
              handleInputChange(e);
            } else {
              setFormState({
                ...formState,
                start_date: "",
              });
            }
          }}
        />
        {errors.start_date && (
          <Alert variant={"danger"} className={"mt-2"}>
            {errors.start_date}
          </Alert>
        )}
      </Form.Group>
      <Form.Group controlId="endDate" className={"mb-4"}>
        <Form.Label>
          End Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="end_date"
          value={formState.end_date || ""}
          min={formState.start_date || todaysDate}
          onChange={(e) => {
            const value = e.target.value;
            let isValueValid = false;
            const minDate = formState.start_date || todaysDate;

            if (value && value < minDate) {
              setErrors({
                ...errors,
                end_date:
                  "End date cannot be less than start date or today's date",
              });
            } else {
              setErrors({
                ...errors,
                end_date: null,
              });
              isValueValid = true;
            }

            if (isValueValid) {
              handleInputChange(e);
            } else {
              setFormState({
                ...formState,
                end_date: "",
              });
            }
          }}
        />
        {errors.end_date && (
          <Alert variant={"danger"} className={"mt-2"}>
            {errors.end_date}
          </Alert>
        )}
      </Form.Group>

      <EntitySelection
        configs={configs}
        formState={formState}
        setFormState={setFormState}
        requiredFields={requiredFields}
        setRequiredFields={setRequiredFields}
      />

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mt-4 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default ScheduleForm;
