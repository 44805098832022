import React, { useContext } from "react";
import { AddItemButton } from "../../../utils/components";
import { useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { Card } from "react-bootstrap";

function PayPeriodsHighlight({
  payPeriods,
  setPayPeriods,
  permissions,
  putRequest,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);

  return (
    <div>
      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title>Pay Periods</Card.Title>
          <Card.Subtitle as={"p"} className={"mt-3"}>
            Customize pay cycles to match your organization's preferences, from
            weekly to monthly and more, for streamlined and precise payroll
            management.
          </Card.Subtitle>
        </Card.Header>
        <Card.Body></Card.Body>
      </Card>
    </div>
  );
}

export default PayPeriodsHighlight;
