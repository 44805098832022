import React from "react";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { Button, OverlayTrigger, Dropdown } from "react-bootstrap";
import { EmployeePopover } from "../EmployeeCards";
import { StatusBadge } from "../../../utils/components";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import EmployeeHighlightCard from "./EmployeeHighlightCard";
import { formatDate } from "../../../utils/dateTime";

export function TimeOffRequestTableRow({
  request,
  onShowApproversClick,
  actions,
  policy,
  index,
}) {
  return (
    <tr>
      {request.employee && (
        <td>
          <EmployeeHighlightCard employee={request.employee} />
        </td>
      )}
      <td>{formatDate(request.timestamp)}</td>
      <td>
        <OverlayTrigger
          key={request.author_employee.id}
          trigger="click"
          placement="right"
          overlay={<EmployeePopover employee={request.author_employee} />}
          rootClose
        >
          <Button variant="link" className="">
            {request.author_employee.full_name}
          </Button>
        </OverlayTrigger>
      </td>
      <td>{request.comment}</td>
      <td className="text-primary">
        <strong>
          {request.time_units} {policy.time_unit}
        </strong>
      </td>
      <td>{formatDate(request.start_date)}</td>
      <td>{formatDate(request.end_date)}</td>

      <td>
        <div
          className={"pointer"}
          onClick={() => {
            onShowApproversClick(request.approval_steps);
          }}
        >
          <StatusBadge
            status={request.status}
            className={"text-decoration-underline"}
          />
        </div>
      </td>
      {actions.length > 0 && (
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant="transparent"
              id="dropdown-basic"
              // as={BsThreeDotsVertical}
            >
              <BsThreeDots />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {actions.map((action, i) => (
                <Dropdown.Item
                  onClick={() => action.onClick(request, index)}
                  key={i}
                >
                  {action.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      )}
    </tr>
  );
}

export function TimeOffRequestsTable({
  requests,
  onShowApproversClick,
  actions,
  policies,
  showEmployee = true,
}) {
  return (
    <Table
      striped
      responsive
      bordered
      hover
      className="mb-4 text-center align-middle"
    >
      <thead className={"bg-primary"}>
        <tr className={"bg-primary"}>
          {showEmployee && <th>For</th>}
          <th>Submitted On</th>
          <th>By</th>
          <th>Reason</th>
          <th>Duration</th>
          <th>From</th>
          <th>To</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {requests.map((request, index) => {
          const policy = policies[request.time_off_policy_id];
          return (
            <TimeOffRequestTableRow
              request={request}
              key={index}
              index={index}
              onShowApproversClick={onShowApproversClick}
              actions={actions(request)}
              policy={policy}
            />
          );
        })}
      </tbody>
    </Table>
  );
}
