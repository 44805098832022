import React, { useContext } from "react";
import { AddItemButton } from "../../../utils/components";
import { LevelsForm } from "../../Forms/Levels";
import { updateStateAtIndex, useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import ShiftForm from "../../Forms/People/ShiftForm";
import { Badge, Button, Card, Table } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard";

function ManageShifts({
  shifts,
  setShifts,
  permissions,
  putRequest,
  patchRequest,
  loaderCallback,
  configs,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);

  return (
    <div>
      {permissions.MANAGE_SHIFTS && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Shift"}
            onClick={() => {
              updateContextState({
                formData: {},
              });
              showOffCanvas({
                title: `Add a Shift`,
                subtitle: `Add a new shift to your organization`,
                component: (
                  <ShiftForm
                    onSubmit={(updatedData) => {
                      putRequest(
                        "/people/shifts",
                        loaderCallback,
                        (response) => {
                          setShifts([response.shift, ...shifts]);
                        },
                        updatedData
                      );
                    }}
                    configs={{
                      days: configs.days,
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      )}

      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title>Shifts</Card.Title>
          <Card.Subtitle as={"p"} className={"mt-3"}>
            Shifts offers a seamless solution for managing employee work shifts.
            With this powerful tool, you can easily create, edit, and assign
            shifts to your workforce, ensuring smooth coverage and avoiding
            scheduling conflicts. Our Shifts feature provides real-time
            visibility of employee schedules, allowing you to efficiently plan
            and track attendance.
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead className={"py-3"}>
              <tr className={"text-center align-middle th-trans"}>
                <th>Created By</th>
                <th>Name</th>
                <th>Description</th>
                <th>Start Time</th>
                <th>Earliest Start Time</th>
                <th>End Time</th>
                <th>Break Time</th>
                <th>Active Days</th>
                <th>Status</th>
                {permissions.MANAGE_SHIFTS && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {shifts.map((shift, index) => (
                <tr key={index} className={"text-center align-middle"}>
                  <td className={"text-start"}>
                    <EmployeeHighlightCard employee={shift.author_employee} />
                  </td>
                  <td>{shift.name}</td>
                  <td>{shift.description}</td>
                  <td>{shift.start_time}</td>
                  <td>{shift.min_start_time}</td>
                  <td>{shift.end_time}</td>
                  <td>{shift.break_time} min</td>
                  <td>
                    {shift.active_days_full.map((day, i) => (
                      <span key={i}>
                        {day}
                        <br />
                      </span>
                    ))}
                  </td>
                  <td>
                    {shift.is_active ? (
                      <Badge bg={"success"}>Active</Badge>
                    ) : (
                      <Badge bg={"danger"}>Inactive</Badge>
                    )}
                  </td>
                  {permissions.MANAGE_SHIFTS && (
                    <td>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          updateContextState({
                            formData: { ...shift },
                          });
                          showOffCanvas({
                            title: `Edit Shift`,
                            subtitle: `Edit the details of this shift`,
                            component: (
                              <ShiftForm
                                isEdit={true}
                                onSubmit={(updatedData) => {
                                  patchRequest(
                                    `/people/shifts/${shift.id}`,
                                    loaderCallback,
                                    (response) => {
                                      updateStateAtIndex(
                                        shifts,
                                        setShifts,
                                        response.shift,
                                        index
                                      );
                                    },
                                    updatedData
                                  );
                                }}
                                configs={{
                                  days: configs.days,
                                }}
                              />
                            ),
                          });
                        }}
                      >
                        <FaEdit />
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ManageShifts;
