import React, { useEffect, useState } from "react";
import EmployeeTable from "../EmployeeTable";
import { AppRoutes } from "../../../utils/appRoutes";
import PaginationComponent from "../../../utils/components/PaginationComponent";
import { LoadingView } from "../../../utils/components";

function PaginatedEmployeesDisplay({
  endpoint,
  onItemClick,
  getRequest,
  hide,
  extraColumns,
}) {
  const [employees, setEmployees] = useState({});
  const [pagingInfo, setPagingInfo] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getPageData = (page) => {
    const url = `${endpoint}/${page}`;
    const pageStr = page.toString();

    if (!Object.keys(employees).includes(pageStr)) {
      getRequest(url, setIsLoading, (response) => {
        setEmployees({
          ...employees,
          [page]: response.employees,
        });
        setPagingInfo({
          ...pagingInfo,
          [page]: response.paging_info,
        });
      });
    }
    setActiveIndex(page);
  };

  useEffect(() => {
    getPageData(1);
  }, []);

  if (hide) {
    // Hiding because if we conditionally render this component, the state will be reset and we will lose the pagination data
    return null;
  }

  return (
    <LoadingView
      fullHeight={false}
      centerLoader={false}
      isLoading={isLoading}
      view={
        <div>
          <EmployeeTable
            pagingInfo={pagingInfo[activeIndex] || {}}
            employees={employees[activeIndex] || []}
            extraColumns={extraColumns}
            onClick={
              onItemClick
                ? (employee) => {
                    onItemClick(employee);
                  }
                : null
            }
          />

          {employees[activeIndex] && employees[activeIndex].length > 0 && (
            <div className={"mt-5 d-flex justify-content-end"}>
              <PaginationComponent
                pagingInfo={pagingInfo[activeIndex] || {}}
                onPageChange={(page) => {
                  getPageData(page);
                }}
              />
            </div>
          )}
        </div>
      }
    />
  );
}

export default PaginatedEmployeesDisplay;
