import React from "react";
import { FaPlus, FaChevronRight } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Card } from "react-bootstrap";

function EmployeeHighlightCard({
  employee,
  action,
  add,
  showIcon,
  index,
  limitSize = false,
}) {
  return (
    <Card
      body={true}
      as={"div"}
      className={`${limitSize ? "emp-card-size-limit" : ""}`}
    >
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className={"d-flex flex-row align-items-center"}>
          <img
            src={employee.profile_image}
            alt={employee.full_name}
            width={50}
            height={50}
            className="rounded-circle me-3"
          />
          <div className={"text-start"}>
            {employee.full_name}
            <div className="small mt-2">
              {employee?.position?.title || "No Position"} - (
              {employee.employee_number})
            </div>
          </div>
        </div>
        {action && !employee.no_action && (
          <div
            className={"pointer"}
            onClick={() => {
              action(employee, index);
            }}
          >
            {add && showIcon ? (
              <FaPlus />
            ) : !add && showIcon ? (
              <AiOutlineClose />
            ) : (
              <FaChevronRight />
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

export default EmployeeHighlightCard;
