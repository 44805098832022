import React, { useContext } from "react";
import { Card, Button, Badge } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { formatDate } from "../../utils/dateTime";
import { EmployeeList } from "./EmployeeCards";
import { AppContext } from "../../utils/components/AppContext";

export function DepartmentCard({ department, canManage, onEditClick }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  return (
    <Card className={"shadow rounded-3 mb-4"}>
      <Card.Header>
        <Card.Title>{department.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          Code: {department.code}
        </Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <Card.Text>{department.description}</Card.Text>
        <Card.Text>
          <b>Date Formed:</b> {formatDate(department.date_formed)}
        </Card.Text>
        <Card.Text>
          <b>Head of {labelsOverride.department || "Department"}:</b>{" "}
          {department.head ? (
            <div className={"mt-2"}>
              <EmployeeList employees={[department.head]} showIcon={false} />
            </div>
          ) : (
            "Not Assigned"
          )}
        </Card.Text>
      </Card.Body>
      {canManage && (
        <Card.Footer>
          <div className="d-flex justify-content-end py-4">
            {onEditClick && (
              <Button
                onClick={() => {
                  onEditClick();
                }}
                variant="primary"
              >
                <FaEdit className={"me-2"} />
                Edit
              </Button>
            )}
          </div>
        </Card.Footer>
      )}
    </Card>
  );
}
