import React, { useContext, useEffect, useState } from "react";
import {
  AddItemButton,
  ConditionalView,
  LoadingView,
} from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { isEmpty, useOffCanvas, useToast } from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { OffCanvasContext } from "../utils/components/OffCanvasContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, Tab, TabContainer, TabContent, Tabs } from "react-bootstrap";
import EmployeeForm from "./Forms/Employee";
import DeductionsForm from "./Forms/Payroll/DeductionsForm";
import DeductionsCards from "./components/Payroll/DeductionsCards";
import PayrollSettings from "./components/Payroll/PayrollSettings";
import PayrollDeductions from "./components/Payroll/PayrollDeductions";
import PayPeriodsHighlight from "./components/Payroll/PayPeriodsHighlight";

export function PayrollManagementPage({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext);

  const { permissions } = contextState;

  useEffect(() => {
    getPayrollManagementInfo();
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };

  const [activeTab, setActiveTab] = useState(undefined);
  const [configs, setConfigs] = useState({});
  const [compensations, setCompensations] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [payPeriods, setPayPeriods] = useState([]);
  const [settings, setSettings] = useState({});
  const [payroll, setPayroll] = useState({});
  const [secondLoader, setSecondLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getPayrollManagementInfo = async () => {
    getRequest(`/payroll/overview`, setIsLoading, (response) => {
      setPayroll(response.payroll);
      setDeductions(response.payroll.deductions);
      setCompensations(response.payroll.compensations);
      setConfigs(response.configs);
      setSettings(response.settings);

      const activeTabParam = searchParams.get("activeTab");
      if (activeTabParam) {
        setActiveTab(activeTabParam);
      }
    });
  };

  const activateTab = (key) => {
    setActiveTab(key);
    updateQueryParam("activeTab", key);
  };
  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ConditionalView
          condition={payroll}
          trueView={
            <Tabs
              activeKey={activeTab}
              onSelect={(key) => activateTab(key)}
              variant={"underline"}
            >
              {!isEmpty(settings.id) && (
                <Tab eventKey="overview" title="Overview"></Tab>
              )}

              {permissions.MANAGE_PAYROLL && !isEmpty(settings.id) && (
                <Tab eventKey="Pay Periods" title="Pay Periods">
                  <LoadingView
                    isLoading={secondLoader}
                    centerLoader={false}
                    view={
                      <>
                        <PayPeriodsHighlight
                          payPeriods={payPeriods}
                          setPayPeriods={setPayPeriods}
                          permissions={permissions}
                          putRequest={putRequest}
                          loaderCallback={setSecondLoader}
                        />
                      </>
                    }
                  />
                </Tab>
              )}

              {permissions.MANAGE_PAYROLL && !isEmpty(settings.id) && (
                <Tab eventKey="taxAndDeduction" title="Taxes & Deductions">
                  <LoadingView
                    isLoading={secondLoader}
                    centerLoader={false}
                    view={
                      <>
                        <PayrollDeductions
                          deductions={deductions}
                          setDeductions={setDeductions}
                          configs={configs}
                          permissions={permissions}
                          putRequest={putRequest}
                          patchRequest={patchRequest}
                          loaderCallback={setSecondLoader}
                        />
                      </>
                    }
                  />
                </Tab>
              )}
              <Tab eventKey="settings" title="Settings">
                <LoadingView
                  isLoading={secondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <PayrollSettings
                        settings={settings}
                        setSettings={setSettings}
                        permissions={permissions}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        loaderCallback={setSecondLoader}
                        configs={configs}
                      />
                    </>
                  }
                />
              </Tab>
            </Tabs>
          }
          falseView={<div></div>}
        />
      }
    />
  );
}

function PayrollManagement() {
  return <DashboardPageContainer PageComponent={PayrollManagementPage} />;
}
export default PayrollManagement;
