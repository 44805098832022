import React, { useContext, useState } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import {
  formatDateStrings,
  formDate,
  getUTCDateTime,
} from "../../../utils/dateTime";
import { isEmpty, updateObjectState } from "../../../utils/functions";
import { ConditionalView } from "../../../utils/components";

function CompensationForm({ onSubmit, configs, wageType, editView = false }) {
  const { contextState, updateContextState } = useContext(AppContext);

  let initData = { ...contextState.formData };
  if (!editView) {
    initData.deduction_ids = [];
    initData.start_date = formDate(getUTCDateTime());
  }

  const [formState, setFormState] = useState(formatDateStrings(initData));

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    const requiredFields = [
      "name",
      "type",
      "amount_type",
      "frequency",
      "currency",
      "amount",
      "start_date",
    ];
    return requiredFields.every((field) => formState[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>
      <Form.Group controlId="name" className={"mb-4"}>
        <Form.Label>
          Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formState.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="description" className={"mb-4"}>
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="isActive" className={"mb-4"}>
        <Form.Switch
          type="checkbox"
          label="Is Active"
          name="is_active"
          checked={formState.is_active || false}
          onChange={(e) =>
            handleInputChange({
              target: { name: "is_active", value: e.target.checked },
            })
          }
        />
      </Form.Group>

      <Form.Group controlId="amountType" className={"mb-4"}>
        <Form.Label>
          Amount Type<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="amount_type"
          value={formState.amount_type || ""}
          disabled={editView}
          onChange={(e) => {
            updateObjectState(setFormState, {
              type: null,
            });
            handleInputChange(e);
          }}
        >
          <option value="">Select an Amount Type</option>
          {configs.compensation_amount_types?.map((amountType) => (
            <option key={amountType} value={amountType}>
              {amountType}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="type" className={"mb-4"}>
        <Form.Label>
          Compensation Type<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="type"
          value={formState.type || ""}
          onChange={handleInputChange}
          disabled={editView}
        >
          <option value="">Select a Type</option>
          {configs.compensation_types?.map((type) => {
            if (
              (type !== "Salary" && formState.amount_type === "Percentage") ||
              formState.amount_type !== "Percentage"
            ) {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            }
          })}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="frequency" className={"mb-4"}>
        <Form.Label>
          Frequency<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="frequency"
          value={formState.frequency || ""}
          onChange={handleInputChange}
          disabled={editView}
        >
          <option value="">Select a Frequency</option>
          {configs.compensation_frequencies?.map((frequency) => (
            <option key={frequency} value={frequency}>
              {frequency}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="currency" className={"mb-4"}>
        <Form.Label>
          Currency<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="currency"
          value={formState.currency || ""}
          onChange={handleInputChange}
          disabled={editView}
        >
          <option value="">Select a Currency</option>
          {configs.currencies?.map((currency) => {
            return (
              <option key={currency} value={currency}>
                {currency}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>

      {formState.amount_type === "Fixed Amount" && (
        <Form.Group controlId="amount" className={"mb-4"}>
          {wageType === "Hourly" && formState.type === "Salary" && (
            <Alert variant={"warning"}>
              <Alert.Heading>Warning</Alert.Heading>
              Enter the hourly rate for this emoployee only. Their pay will be
              calculated based on the hours they work during each pay period.
            </Alert>
          )}
          {wageType === "Salary" && formState.type === "Salary" && (
            <Alert variant={"warning"}>
              <Alert.Heading>Warning</Alert.Heading>
              Enter the total salary of the employee for the YEAR. We will
              automatically calculate their pay based on the yearly salary, for
              the pay period frequency you specify.
            </Alert>
          )}
          <Form.Label>
            {wageType} Amount<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="number"
            name="amount"
            value={formState.amount || ""}
            onChange={handleInputChange}
            disabled={editView}
            readOnly={editView}
          />
        </Form.Group>
      )}

      {formState.amount_type === "Percentage" && (
        <Form.Group controlId="percentageDecimal" className={"mb-4"}>
          <Form.Label>
            Percentage of Salary (%)<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="number"
            name="percentage_decimal"
            value={formState.amount || ""}
            onChange={handleInputChange}
            disabled={editView}
            readOnly={editView}
          />
        </Form.Group>
      )}

      {!isEmpty(formState.currency) && (
        <Form.Group controlId="deductions" className={"mb-4"}>
          <Form.Label>Deductions / Taxes</Form.Label>
          <div className={"mb-3"}>
            <Form.Text>
              Select deduction / taxes that apply to this compensation if any.
            </Form.Text>
          </div>

          {configs.deductions.map((deduction, index) => {
            if (deduction.currency !== formState.currency) {
              return <div key={`${index}-j`} />;
            }

            return (
              <Card key={index} body={true} className={"mb-2"}>
                <Form.Check
                  type="checkbox"
                  id={`deduction_ids-${index}`}
                  label={deduction.name}
                  name="deduction_ids"
                  value={deduction.id}
                  checked={
                    formState.deduction_ids?.includes(deduction.id) || false
                  }
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    updateObjectState(setFormState, {
                      deduction_ids: isChecked
                        ? [...formState.deduction_ids, e.target.value]
                        : formState.deduction_ids.filter(
                            (id) => id !== e.target.value
                          ),
                    });
                  }}
                />
                <div className={"mt-3"}>
                  <ConditionalView
                    condition={deduction.amount_type === "Percentage"}
                    trueView={
                      <span>
                        Total Deduction: {deduction.total}%
                        <br />
                        Deduction from Salary: {deduction.percentage_decimal}%
                        <br />
                        Organization Pays:{" "}
                        {deduction.organization_pay_percentage_decimal}%
                      </span>
                    }
                    falseView={
                      <span>
                        Total Deduction: {formState.currency} {deduction.total}
                        <br />
                        Deduction from Salary: {formState.currency}{" "}
                        {deduction.amount}
                        <br />
                        Organization Pays: {formState.currency}{" "}
                        {deduction.organization_pay_amount}
                      </span>
                    }
                  />
                </div>
              </Card>
            );
          })}
        </Form.Group>
      )}

      <Form.Group controlId="startDate" className={"mb-4"}>
        <Form.Label>
          Effective Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="start_date"
          value={formState.start_date || ""}
          onChange={handleInputChange}
          disabled={editView}
          readOnly={editView}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default CompensationForm;
