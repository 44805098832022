import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Form,
  FormGroup,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Brand from "./Brand";
import { BsBook, BsHouse, BsGraphUp, BsWallet, BsPeople } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { AppRoutes } from "../../utils/appRoutes";
import {
  FaMoneyBillWave,
  FaChartBar,
  FaUserTie,
  FaCog,
  FaSitemap,
  FaUserPlus,
  FaUsers,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import ThemeSwitch from "../../utils/components/ThemeSwitch";
import { AppContext } from "../../utils/components/AppContext";
import { FaCircleDot, FaFolderTree } from "react-icons/fa6";
import { toTitleCase } from "../../utils/functions";
import { GoDotFill } from "react-icons/go";
import { ConditionalView } from "../../utils/components";

const ACTIVE_CLASSES =
  "text-primary bg-primary bg-opacity-25 rounded-2 fw-bolder ms-1 me-1";

function LeftNavbar({ permissions }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const { pathname } = useLocation();
  const location = useLocation();
  const [expandAll, setExpandAll] = useState(
    localStorage.getItem("expandAll") === "true"
  );

  const { navLinks } = contextState;

  useEffect(() => {
    localStorage.setItem("expandAll", expandAll);
  }, [expandAll]);

  const handleExpandAllChange = (event) => {
    setExpandAll(event.target.checked);
  };
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const sideMenuOptions = contextState.sideMenuOptions || [];

  return (
    <Navbar
      expand="lg"
      // className={"posicol-2"}
      style={{ paddingTop: -40, paddingBottom: 150 }}
    >
      <Nav className="flex-column w-100">
        <Navbar.Brand className={"ms-1 mb-4"}>
          <Brand
            marginStart={"ms-1"}
            nameColor={""}
            imageWidth={40}
            nameSize={"fs-6"}
          />
        </Navbar.Brand>

        <Form.Group className={"ms-3"}>
          <Form.Check
            type="switch"
            id="expand-all-switch"
            label="Expand All"
            checked={expandAll}
            onChange={handleExpandAllChange}
            className="mb-3"
          />
        </Form.Group>

        {navLinks.map((item, i) => {
          if (!sideMenuOptions.includes(item.name) && !item.alwaysShow) {
            return null;
          }

          return (
            <div key={i}>
              <LinkContainer
                to={item.path}
                key={i}
                onClick={(e) => {
                  if (item.tabs) {
                    e.preventDefault();
                    if (expandAll) return;

                    const allLinks = [...navLinks];
                    const currentLink = allLinks[i];
                    currentLink.expandTabs = !currentLink.expandTabs;
                    allLinks[i] = currentLink;
                    updateContextState({
                      navLinks: allLinks,
                    });
                  }
                }}
              >
                <Nav.Link
                  onMouseEnter={() => handleMouseEnter(i)}
                  onMouseLeave={handleMouseLeave}
                  className={
                    (hoveredIndex === i || pathname.includes(item.path)
                      ? ACTIVE_CLASSES
                      : "") +
                    " mb-2 ps-3 d-flex justify-content-between align-items-center"
                  }
                >
                  <div className="d-flex align-items-center">
                    <item.icon className="me-2 fs-5" />
                    {item.name}
                  </div>
                  {item.tabs &&
                    (item.expandTabs ? (
                      <FaChevronUp size={13} className={"me-2"} />
                    ) : (
                      <FaChevronDown size={13} className={"me-2"} />
                    ))}
                </Nav.Link>
              </LinkContainer>
              {item.tabs && (item.expandTabs || expandAll) && (
                <Nav className="flex-column">
                  {Object.entries(item.tabs).map(([tabKey, tabValue]) => {
                    const { hasPermission, otherName } = tabValue;
                    if (hasPermission) {
                      return (
                        <LinkContainer
                          to={{
                            pathname: item.path,
                            search: `?activeTab=${tabKey}`,
                          }}
                          key={tabKey}
                        >
                          <Nav.Link
                            className={
                              (pathname.includes(item.path) &&
                              new URLSearchParams(location.search).get(
                                "activeTab"
                              ) === tabKey
                                ? ACTIVE_CLASSES
                                : "") + " mb-2 ps-5"
                            }
                          >
                            <GoDotFill
                              className={"me-2 text-primary"}
                              size={10}
                            />
                            {toTitleCase(otherName || tabKey)}
                          </Nav.Link>
                        </LinkContainer>
                      );
                    }
                    return null;
                  })}
                </Nav>
              )}
            </div>
          );
        })}
      </Nav>
      <Card
        body={true}
        className="col-11 col-lg-2 position-fixed bottom-0 py-3"
        style={{
          marginLeft: -12,
        }}
      >
        <ThemeSwitch />
      </Card>
    </Navbar>
  );
}

export default LeftNavbar;
