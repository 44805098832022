import React from "react";
import InfoCard from "../../../utils/components/InfoCard";
import { Card, Col, Row, Form } from "react-bootstrap";
import { isEmpty, toTitleCase, useAppModal } from "../../../utils/functions";
import { AppBarChart, AppPieChart } from "../../../utils/components/AppCharts";
import { LoadingView } from "../../../utils/components";
import { AppConstants } from "../../../utils/appConstants";

const PeopleStats = ({ overview, includeAddChildrenOption = true }) => {
  const { showAppModal } = useAppModal();

  const childrenIncluded =
    localStorage.getItem(AppConstants.peopleStatsIncludeDescendantsKey) ===
    "true";

  return (
    <>
      <h1 className={"text-center mb-4 mt-4"}>People Statistics</h1>
      <Card>
        {includeAddChildrenOption && (
          <Card.Header className={"py-4"}>
            <Form.Group>
              <Form.Check
                type="switch"
                size={"xl"}
                id="include-descendants-switch"
                label={<Card.Title>Include all child organizations</Card.Title>}
                checked={
                  localStorage.getItem(
                    AppConstants.peopleStatsIncludeDescendantsKey
                  ) === "true"
                }
                onChange={(e) => {
                  localStorage.setItem(
                    AppConstants.peopleStatsIncludeDescendantsKey,
                    e.target.checked
                  );
                  window.location.reload();
                }}
              />
            </Form.Group>

            <Card.Subtitle as={"p"} className={"pt-2"}>
              This will reload the page and{" "}
              {childrenIncluded ? "include" : "exclude"} all child organizations
              in the stats.
              <br />
              Note that the "child organizations" will only include the
              organizations that are below and report up to this organization in
              the organization tree.
            </Card.Subtitle>
          </Card.Header>
        )}
        <Card.Body>
          <div className="mb-5">
            <LoadingView
              isLoading={isEmpty(overview.totals)}
              fullHeight={false}
              centerLoader={false}
              text={"Fetching Data..."}
              spinnerText={""}
              view={
                <Row>
                  {Object.entries(overview?.totals || {}).map(
                    ([title, number], i) => (
                      <InfoCard
                        key={i}
                        header={toTitleCase(title)}
                        body={number}
                        lg={4}
                        colClass={"mb-4"}
                      />
                    )
                  )}
                </Row>
              }
            />

            <Row className={"my-5"}>
              <Col lg={6}>
                <h3 className={"text-center mb-3"}>Gender Distribution </h3>
                <AppPieChart data={overview.gender_distribution} />
              </Col>
              <Col lg={6}>
                <h3 className={"text-center mb-3"}>Employee Types</h3>
                <AppPieChart data={overview.employee_types} />
              </Col>
            </Row>

            <div className={"mb-5"}>
              <h3 className={"text-center mb-5"}>Employees Age Brackets</h3>
              <AppBarChart
                data={overview.age_brackets}
                xName={"Age Brackets"}
              />
            </div>

            <div className="mb-5">
              <h3 className={"text-center mb-5"}>Nationalities Distribution</h3>
              <AppBarChart
                data={overview.employee_nationalities}
                xName={"Employees"}
              />
            </div>

            <div className="text-center">
              <h3 className={"text-center mb-5"}>Employees Per Team</h3>
              <AppBarChart
                data={overview.employees_per_teams}
                xName={"Employees"}
              />
            </div>

            <div className="chart w-100 mt-5 pt-5 pl-4">
              <h3 className={"text-center mb-5"}>Positions Distribution</h3>
              <AppBarChart
                data={overview.positions_distribution}
                xName={"Positions"}
              />
            </div>

            <div className={"chart w-100 mt-5 pt-5"}>
              <h3 className={"text-center mb-5"}>
                Level to Positions Distribution
              </h3>
              <AppPieChart data={overview.levels_to_positions} />
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default PeopleStats;
