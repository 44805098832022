import humnceWhiteColorOutlineLogo from "../src/assets/logos/white-color-outline.png";
import goslLogo from "../src/assets/gosl/logos/gosl-logo.png";
import GoSLIndexPage from "./pages/gosl/GoslIndexPage";

const webAddress = "humnce.com";
const env = process.env.REACT_APP_ENV;

const sharedConfigs = {
  appName: "humnce",
  googleMapsApiKey: "AIzaSyARJ4EeZ---l95ztYsu7hdiO5__qE0DHL8",
  isDev: false,
  isStg: false,
  isProd: false,
  primaryLogo: humnceWhiteColorOutlineLogo,
  appName: "humnce",
  companyName: "humnce LLC",

  // apiHostname: "http://10.0.0.104:5000",
  // feHostname: "http://10.0.0.104:3000",
  apiHostname: "http://localhost:5000",
  feHostname: "http://localhost:3000",
};

const configs = {
  dev: {
    ...sharedConfigs,
    ...{
      isDev: true,
    },
  },
  stg: {
    ...sharedConfigs,
    ...{
      isStg: true,
      apiHostname: `https://stg-api.${webAddress}`,
      feHostname: `https://stg.${webAddress}`,
    },
  },
  prod: {
    ...sharedConfigs,
    ...{
      isProd: true,
      apiHostname: `https://api.${webAddress}`,
      feHostname: `https://${webAddress}`,
    },
  },
  "gosl-prod": {
    ...sharedConfigs,
    ...{
      IndexPage: GoSLIndexPage,
      isProd: true,
      apiHostname: `https://gosl-api.${webAddress}`,
      feHostname: `https://gosl.${webAddress}`,
      primaryLogo: goslLogo,
      appName: "GoSL HR Hub",
      companyName: "Government of Sierra Leone",
    },
  },
};

export const appConfigs = configs[env];
