import React, { useContext, useEffect, useState } from "react";
import { LoadingView } from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  updateObjectState,
  updateStateAtIndex,
  useAppModal,
  useOffCanvas,
  useToast,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { OffCanvasContext } from "../utils/components/OffCanvasContext";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { TimeOffRequestsTable } from "./components/Employee/TimeOffRequestTable";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaCheck, FaRegTimesCircle, FaStopCircle } from "react-icons/fa";
import ApprovalsTable from "./components/Employee/ApprovalsTable";
import PaginationComponent from "../utils/components/PaginationComponent";
import PageMetaTags from "./components/Shared/PageMetaTags";
import GenericForm from "./Forms/Shared/GenericForm";

export function TimeOffRequestsReviewPage() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext);
  const { showAppModal, closeModal } = useAppModal();

  const { permissions } = contextState;

  useEffect(() => {
    getTimeOffRequestsReviewInfo();
  }, []);

  const [pages, setPages] = useState({
    current_page: 1,
    total_pages: 1,
    has_next: false,
  });
  const [policies, setPolicies] = useState({});
  const [requests, setRequests] = useState([]);
  const [secondaryLoad, setSecondaryLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getTimeOffRequestsReviewInfo = async (page = null) => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(
      `/employee/time-off-requests/review?page=${page || pages.current_page}`
    );
    if (success) {
      setRequests(response.requests);
      updateObjectState(setPolicies, response.policies);
      setPages(response.pages);
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    setIsLoading(false);
    setSecondaryLoad(false);
  };

  const patchItem = async (
    endpoint,
    onSuccess = () => {},
    vals = null,
    multipart
  ) => {
    closeOffCanvas();
    setIsLoading(true);
    const { success, response } = await apiRequest.patch(
      endpoint,
      vals,
      multipart
    );
    if (success) {
      onSuccess(response);
      showToast({
        message: response.message,
        success: true,
      });
    } else {
      showToast({
        title: "Error Updating Info",
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <>
          <PageMetaTags title={`Time Off Requests Review`} />
          <Card>
            <Card.Header>
              <Card.Title>Time Off Requests Review</Card.Title>
              <Card.Subtitle>
                Please review the requests below and approve or reject them.
              </Card.Subtitle>
              <Card.Body>
                <div className={"mt-4"}>
                  <TimeOffRequestsTable
                    requests={requests}
                    onShowApproversClick={(approvalSteps) => {
                      showAppModal({
                        title: `Approval Status for Request`,
                        hideFooter: true,
                        component: (
                          <div>
                            <ApprovalsTable approvalSteps={approvalSteps} />
                          </div>
                        ),
                      });
                    }}
                    policies={policies}
                    actions={(request) => {
                      const actions = [];
                      if (request.can_update) {
                        actions.push({
                          title: (
                            <p className={"text-success me-2 fw-bold"}>
                              <FaCheck className={"fs-5 me-1"} />
                              Approve Request
                            </p>
                          ),
                          onClick: (request, index) => {
                            showAppModal({
                              title: "APPROVE Time Off Request",
                              hideFooter: true,
                              component: (
                                <div>
                                  <p className={"mb-4"}>
                                    Are you sure you want to APPROVE this
                                    request?
                                    <br />
                                    This cannot be undone by you.
                                  </p>

                                  <div className={"mt-4"}>
                                    <GenericForm
                                      useGlobalState={false}
                                      formConfigs={[
                                        {
                                          valueKey: "comments",
                                          labelName: "Add Comments",
                                          isRequired: false,
                                        },
                                      ]}
                                      onSubmit={(formData) => {
                                        closeModal();
                                        patchItem(
                                          `/employee/time-off-requests/review/update/${request.id}`,
                                          (response) => {
                                            updateStateAtIndex(
                                              requests,
                                              setRequests,
                                              response.request,
                                              index
                                            );
                                          },
                                          {
                                            comments: formData.comments,
                                            status: "Approved",
                                          }
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              ),
                              truthyFunction: () => {},
                            });
                          },
                        });
                        actions.push({
                          title: (
                            <p className={"text-danger me-2 fw-bold"}>
                              <FaRegTimesCircle className={"fs-5 me-1"} />
                              Reject Request
                            </p>
                          ),
                          onClick: (request, index) => {
                            showAppModal({
                              title: "REJECT Time Off Request",
                              hideFooter: true,
                              component: (
                                <div>
                                  <p className={"mb-2"}>
                                    Are you sure you want to REJECT this
                                    request?
                                    <br />
                                    This cannot be undone by you.
                                  </p>
                                  <div className={"mt-4"}>
                                    <GenericForm
                                      useGlobalState={false}
                                      formConfigs={[
                                        {
                                          valueKey: "comments",
                                          labelName: "Reason for Rejection",
                                          isRequired: true,
                                        },
                                      ]}
                                      onSubmit={(formData) => {
                                        closeModal();
                                        patchItem(
                                          `/employee/time-off-requests/review/update/${request.id}`,
                                          (response) => {
                                            updateStateAtIndex(
                                              requests,
                                              setRequests,
                                              response.request,
                                              index
                                            );
                                          },
                                          {
                                            comments: formData.comments,
                                            status: "Rejected",
                                          }
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              ),
                            });
                          },
                        });
                      }
                      return actions;
                    }}
                  />
                </div>
              </Card.Body>
            </Card.Header>
          </Card>

          <div className={"mt-5 d-flex justify-content-end"}>
            <PaginationComponent
              pagingInfo={pages}
              onPageChange={(page) => {
                getTimeOffRequestsReviewInfo(page);
              }}
            />
          </div>
        </>
      }
    />
  );
}

function TimeOffRequestsReview() {
  return <DashboardPageContainer PageComponent={TimeOffRequestsReviewPage} />;
}

export default TimeOffRequestsReview;
