import React, { useContext, useEffect, useState } from "react";
import { ConditionalView, LoadingView, StatusBadge } from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  scrollToItem,
  toQueryParams,
  updateObject,
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "axios";
import { Button, Card, Row, Table } from "react-bootstrap";
import InfoCard from "../utils/components/InfoCard";
import EmployeeHighlightCard from "./components/Employee/EmployeeHighlightCard";
import { AppConstants } from "../utils/appConstants";
import PaginationComponent from "../utils/components/PaginationComponent";
import ScheduleCalendar from "./components/Employee/ScheduleCalendar";
import { FaChevronDown, FaExpand, FaPlus, FaTrash } from "react-icons/fa";
import { AppRoutes } from "../utils/appRoutes";
import ApprovalsTable from "./components/Employee/ApprovalsTable";
import ShiftScheduleAssignmentForm from "./Forms/People/ShiftScheduleAssignmentForm";
import {
  formatDate,
  formDate,
  getTodayFormFormat,
  getUTCDateTime,
  time24Hrs,
} from "../utils/dateTime";
import PageMetaTags from "./components/Shared/PageMetaTags";
import EmployeeSearch from "./components/EmployeeSearch";
import ControlledDateRangeFormFields from "./Forms/ControlledDateRangeFormFields";
import ExtendScheduleForm from "./Forms/People/ExtendScheduleForm";
import { FcRefresh } from "react-icons/fc";

function Schedule({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const { permissions } = contextState;
  const { showAppModal } = useAppModal();
  const { showOffCanvas } = useOffCanvas();

  const navigate = useNavigate();

  useEffect(() => {
    getSchedule();
  }, []);

  const [employeesPaging, setEmployeesPaging] = useState(
    AppConstants.initPaging
  );
  const [viewedAssignments, setViewedAssignments] = useState({});
  const [activeEmployee, setActiveEmployee] = useState({});
  const [employees, setEmployees] = useState([]);
  const [extendDates, setExtendDates] = useState({});
  const [schedule, setSchedule] = useState({});
  const [thirdLoader, setThirdLoader] = useState(false);
  const [secondIsLoading, setSecondIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { scheduleId } = useParams();

  useEffect(() => {
    if (!schedule.id) return;
    getEmployees();
  }, [schedule.id]);

  const getSchedule = async () => {
    getRequest(`/people/schedules/${scheduleId}`, setIsLoading, (response) => {
      setSchedule(response.schedule);
    });
  };

  const enrichedEmployees = (employees) => {
    let newEmployees = [];
    // add the default paging object to all the new employees as shiftPaging
    employees.forEach((employee) => {
      employee.shiftPaging = AppConstants.ininPaging;
      employee.assignments = {};
      employee.active = false;

      const currentMonth = formDate(getUTCDateTime().startOf("month"));

      employee.navigation = {
        previous_month: currentMonth,
        next_month: currentMonth,
        active_month: currentMonth,
      };
      newEmployees.push(employee);
    });
    return newEmployees;
  };

  const getEmployees = async () => {
    getRequest(
      `/people/schedules/${scheduleId}/employees?${toQueryParams(
        employeesPaging
      )}`,
      employeesPaging.current_page > 1 ? setSecondIsLoading : setIsLoading,
      (response) => {
        setEmployeesPaging(response.paging);
        const newEmployees = enrichedEmployees(response.employees);
        setEmployees([...employees, ...newEmployees]);
      }
    );
  };

  const getAssignments = async (employee, index, nav = {}) => {
    let assignmentsData = {};
    setThirdLoader(true);
    if (viewedAssignments[`${employee.id}-${nav.start_date}`]) {
      assignmentsData = {
        ...viewedAssignments[`${employee.id}-${nav.start_date}`],
      };
    } else {
      const params = updateObject({}, nav || employee.navigation);
      await getRequest(
        `/people/schedules/${scheduleId}/employees/${
          employee.id
        }/assignments?${toQueryParams(params)}`,
        setThirdLoader,
        (response) => {
          assignmentsData = response;
          updateObjectState(setViewedAssignments, {
            [`${employee.id}-${response.navigation.active_month}`]: response,
          });
        }
      );
    }
    setThirdLoader(false);
    const emp = { ...employee };
    emp.assignments = assignmentsData.assignments;
    emp.navigation = assignmentsData.navigation;
    const allEmployees = [...employees];
    allEmployees[index] = emp;
    setEmployees(allEmployees);
    scrollToItem(employee.id);
  };

  const updateAssignments = (employee, shift, day, index, delete_ = false) => {
    let assignmentsData = { ...employee.assignments };
    const nav = { ...employee.navigation };
    if (delete_) {
      assignmentsData[day].splice(index, 1);
    } else {
      // A list of shifts will be sent here
      shift.forEach((assignment, index) => {
        assignmentsData[day].splice(index, 1, assignment);
      });
    }
    const viewedKey = `${employee.id}-${nav.active_month}`;

    const viewed = { ...viewedAssignments[viewedKey] };
    viewed.assignments = { ...assignmentsData };
    updateObjectState(setViewedAssignments, {
      [viewedKey]: viewed,
    });
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags title={`Schedule`} />
          <Card className={"mb-5"}>
            <Card.Header className={"py-4"}>
              <div className={"d-flex justify-content-between"}>
                <Card.Title>
                  Schedule for "{schedule?.shift?.name}" Shift
                </Card.Title>
                <div className={"text-center"}>
                  <h6 className={"mb-3"}>Processing Status</h6>
                  <StatusBadge status={schedule.status} />
                </div>
              </div>
              <Card.Subtitle as={"p"} className={"mt-3"}>
                {schedule.description}
              </Card.Subtitle>
            </Card.Header>
            <Card.Body>
              <Row className={"mt-4"}>
                <InfoCard
                  header={"From"}
                  body={formatDate(schedule.start_date)}
                />
                <InfoCard header={"To"} body={formatDate(schedule.end_date)} />
                <InfoCard
                  header={"Total Assigned Shifts"}
                  body={schedule.assignments_count}
                />
                <InfoCard
                  header={"# of Employees"}
                  body={schedule.employees_count}
                />
              </Row>
            </Card.Body>
            <Card.Footer className={"py-3"}>
              <div className={"d-flex justify-content-between"}>
                <div>
                  <Button
                    variant={"outline-primary"}
                    className={"me-2"}
                    onClick={() => {
                      updateContextState({
                        formData: {
                          start_date: getUTCDateTime(schedule.end_date)
                            .add(1, "day")
                            .format("YYYY-MM-DD"),
                          end_date: null,
                        },
                      });

                      showOffCanvas({
                        title: `Extend Schedule`,
                        subtitle: `This will extend the schedule to the new dates and assign shifts to all users in the schedule. The start date will automatically be set to the last end date + 1 day`,
                        component: (
                          <ExtendScheduleForm
                            onSubmit={(updateData) => {
                              postRequest(
                                `/people/schedules/${schedule.id}/assignments-extends`,
                                setIsLoading,
                                (response) => {
                                  setSchedule({
                                    ...schedule,
                                    ...response.schedule,
                                  });
                                },
                                updateData
                              );
                            }}
                          />
                        ),
                      });
                    }}
                  >
                    <FaExpand /> Extend Schedule
                  </Button>
                  <Button
                    variant={"outline-primary"}
                    onClick={() => {
                      showAppModal({
                        title: "Refresh Schedule",
                        component: (
                          <p className={"text-center"}>
                            Refreshing the schedule will add employees that
                            currently meet the entity selection criteria set
                            when creating the schedule
                            <br />
                            Proceed?
                          </p>
                        ),
                        truthyFunction: () => {
                          getRequest(
                            `/people/schedules/${scheduleId}/assignments-refresh`,
                            setIsLoading,
                            (response) => {
                              setSchedule({
                                ...schedule,
                                ...response.schedule,
                              });
                            },
                            true
                          );
                        },
                      });
                    }}
                  >
                    <FcRefresh className={"me-2"} />
                    Refresh Schedule
                  </Button>
                </div>

                <div className={"d-flex justify-content-end"}>
                  <Button
                    variant={"outline-danger"}
                    className={"me-2"}
                    onClick={() => {
                      showAppModal({
                        title: "Delete future shifts in this schedule?",
                        component: (
                          <p>
                            Are you sure you want to delete the future shifts in
                            this schedule? This action cannot be undone. All the
                            future shifts in this schedule will be deleted.
                          </p>
                        ),
                        truthyFunction: () => {
                          deleteRequest(
                            `/people/schedules/${scheduleId}/future-assignments`,
                            setIsLoading,
                            (response) => {
                              navigate(
                                `${AppRoutes.People.path}?activeTab=schedules`
                              );
                            },
                            true
                          );
                        },
                      });
                    }}
                  >
                    <FaTrash className={"me-2"} /> Delete Future Shifts
                  </Button>

                  <Button
                    variant={"outline-danger"}
                    disabled={!schedule.can_delete}
                    onClick={() => {
                      showAppModal({
                        title: "Delete this schedule?",
                        component: (
                          <p>
                            Are you sure you want to delete this schedule? This
                            action cannot be undone. All the scheduled shifts
                            for this schedule will also be deleted
                          </p>
                        ),
                        truthyFunction: () => {
                          deleteRequest(
                            `/people/schedules/${scheduleId}`,
                            setIsLoading,
                            (response) => {
                              navigate(
                                `${AppRoutes.People.path}?activeTab=schedules`
                              );
                            }
                          );
                        },
                      });
                    }}
                  >
                    <FaTrash /> Delete Schedule
                  </Button>
                </div>
              </div>
              {!schedule.can_delete && (
                <h6 className={"mt-2 text-muted text-end"}>
                  This schedule cannot be deleted because
                  <br />
                  employees's have clocked in based on it.
                  <br />
                  You can delete future shifts in this schedule though.
                </h6>
              )}
            </Card.Footer>
          </Card>

          <Card className={"mt-3"}>
            <Card.Header className={"py-3"}>
              <Card.Title>Assigned Employees</Card.Title>
              <Card.Subtitle>
                Please note that this schedule only applies to the shift
                mentioned above. To see an employee's full schedule, please
                click on their name to go to their profile page and click on the
                "Attendance" tab.
              </Card.Subtitle>
            </Card.Header>
            <Card.Body>
              <EmployeeSearch
                onGetResults={(employees) => {
                  setEmployeesPaging({});
                  const newEmployees = enrichedEmployees(employees);
                  setEmployees(newEmployees);
                }}
                returnResults={true}
              />
            </Card.Body>
          </Card>
          {employees.map((employee, index) => (
            <Card key={index} className={"mt-3"} id={employee.id}>
              <Card.Header className={"py-5"}>
                <Row>
                  <InfoCard
                    xs={12}
                    lg={6}
                    header={"Employee"}
                    body={
                      <EmployeeHighlightCard
                        employee={employee}
                        action={() => {
                          navigate(
                            AppRoutes.Employee.paramLessPath + employee.id
                          );
                        }}
                      />
                    }
                  />
                  <InfoCard
                    xs={12}
                    lg={6}
                    header={"# of Shifts"}
                    body={employee.shifts_count}
                  />
                </Row>
              </Card.Header>
              <Card.Body>
                <div className={"d-flex justify-content-between mb-4 mt-2"}>
                  <h4>{employee.first_name}'s Schedule</h4>
                  <Button
                    variant={"outline-primary"}
                    disabled={activeEmployee.id === employee.id}
                    onClick={() => {
                      setActiveEmployee(employee);
                      getAssignments(employee, index, {
                        ...employee.navigation,
                        start_date: employee.navigation.active_month,
                      });
                    }}
                  >
                    View Shifts Schedule <FaChevronDown className={"ms-2"} />
                  </Button>
                </div>
                {activeEmployee.id === employee.id && (
                  <LoadingView
                    isLoading={thirdLoader}
                    centerLoader={false}
                    fullHeight={false}
                    view={
                      <div className={"w-100"}>
                        <ScheduleCalendar
                          activeDate={getUTCDateTime(
                            employee.navigation.active_month
                          )}
                          assignments={employee.assignments}
                          navigation={employee.navigation}
                          navCallback={(nav) => {
                            getAssignments(employee, index, nav);
                          }}
                          canManage={permissions.MANAGE_SCHEDULES}
                          addShiftCallback={(date, day, index) => {
                            updateContextState({
                              formData: {
                                date: date,
                                employee_id: employee.id,
                              },
                            });

                            showOffCanvas({
                              title: `Add a Shift for ${employee.first_name}`,
                              subtitle: `This will add the shift to this schedule.`,
                              component: (
                                <ShiftScheduleAssignmentForm
                                  readOnlyDate={true}
                                  onSubmit={(updateData) => {
                                    putRequest(
                                      `/people/schedules/${schedule.id}/assignments`,
                                      setThirdLoader,
                                      (response) => {
                                        updateAssignments(
                                          employee,
                                          response.assignments,
                                          day,
                                          index,
                                          false
                                        );
                                      },
                                      updateData
                                    );
                                  }}
                                />
                              ),
                            });
                          }}
                          editShiftCallback={(date, day, shift, index) => {
                            updateContextState({
                              formData: {
                                date: date,
                                ...shift,
                                start_datetime: time24Hrs(shift.start_datetime),
                                end_datetime: time24Hrs(shift.end_datetime),
                              },
                            });

                            showOffCanvas({
                              title: `Edit Shift for ${employee.first_name}`,
                              subtitle: `This will modify the shift in this schedule.`,
                              component: (
                                <ShiftScheduleAssignmentForm
                                  readOnlyDate={true}
                                  onSubmit={(updateData) => {
                                    patchRequest(
                                      `/people/schedule-assignments/${shift.id}`,
                                      setThirdLoader,
                                      (response) => {
                                        updateAssignments(
                                          employee,
                                          response.assignments,
                                          day,
                                          index,
                                          false
                                        );
                                      },
                                      updateData
                                    );
                                  }}
                                />
                              ),
                            });
                          }}
                          deleteShiftCallback={(shift, index, day) => {
                            showAppModal({
                              title: `Delete Shift`,
                              hideFooter: false,
                              component: (
                                <div>
                                  Are you sure you want to delete this shift?
                                </div>
                              ),
                              truthyFunction: () => {
                                deleteRequest(
                                  `/people/schedule-assignments/${shift.id}`,
                                  setThirdLoader,
                                  (response) => {
                                    updateAssignments(
                                      employee,
                                      response.shift,
                                      day,
                                      index,
                                      true
                                    );
                                  }
                                );
                              },
                            });
                          }}
                        />
                      </div>
                    }
                  />
                )}
              </Card.Body>
            </Card>
          ))}
          <div className={"mt-5"}>
            <LoadingView
              isLoading={secondIsLoading}
              centerLoader={false}
              fullHeight={false}
              view={
                <div className={"d-flex justify-content-center mb-5 pb-5"}>
                  <ConditionalView
                    condition={employeesPaging.has_next}
                    trueView={
                      <Button
                        variant={"outline-primary"}
                        size={"lg"}
                        onClick={() => {
                          getEmployees();
                        }}
                        className={"w-100"}
                      >
                        Load More...
                      </Button>
                    }
                    falseView={<div />}
                  />
                </div>
              }
            />
          </div>
        </div>
      }
    />
  );
}

function SchedulePage() {
  return <DashboardPageContainer PageComponent={Schedule} />;
}

export default SchedulePage;
