import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AppOffCanvasLocal,
  ConditionalView,
  LoadingView,
} from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  flattenArrayWithChildren,
  toTitleCase,
  updateObjectState,
  useOffCanvas,
  useToast,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { Card, Tab, Tabs } from "react-bootstrap";
import { OffCanvasContext } from "../utils/components/OffCanvasContext";
import UpdateMembersForm from "./Forms/UpadteEmployees";
import { useNavigate, useSearchParams } from "react-router-dom";
import PeopleStats from "./components/People/PeopleStats";
import ManagePeople from "./components/People/ManagePeople";
import ManageTeams from "./components/People/ManageTeams";
import ManagePositions from "./components/People/ManagePositions";
import ManageLevels from "./components/People/ManageLevels";
import ManageTimeOffPolicies from "./components/People/ManageTimeOffPolicies";
import ManageShifts from "./components/People/ManageShifts";
import ManageSchedules from "./components/People/ManageSchedules";
import ManageAnnouncements from "./components/People/ManageAnnouncements";
import EmployeesActivities from "./components/People/EmployeesActivities";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { AppConstants } from "../utils/appConstants";
import EmployeesAttendanceView from "./components/Shared/EmployeesAttendanceView";
import organization from "./Forms/Organization";

export function PeopleManagementPage({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  const { permissions } = contextState;

  useEffect(() => {
    // activateTab(searchParams.get("activeTab"));
  }, []);

  const [fetchStatus, setFetchStatus] = useState({
    people: false,
    teams: false,
    levels: false,
    positions: false,
    shifts: false,
    schedules: false,
    timeOffPolicies: false,
    announcements: false,
    attendance: false,
    activities: false,
  });

  const [data, setData] = useState({});
  const [peoplePagingInfo, setPeoplePagingInfo] = useState({});
  const [configs, setConfigs] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [overview, setOverview] = useState({});
  const [workflowApprovalPositions, setWorkflowApprovalPositions] = useState(
    []
  );
  const [positions, setPositions] = useState([]);
  const [levels, setLevels] = useState([]);
  const [teams, setTeams] = useState([]);
  const [timeOffApprovalWorkflows, setTimeOffApprovalWorkflows] = useState([]);
  const [timeOffPolicies, setTimeOffPolicies] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [announcements, setAnnouncements] = useState({});
  const [showSecondLoader, setShowSecondLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const tabsRef = useRef([]);

  const [showAppOffCanvas, setShowAppOffCanvas] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const tabPermissions = {
    overview: permissions.VIEW_EMPLOYEES_STATS,
    people: permissions.VIEW_EMPLOYEE_DATA,
    teams: permissions.MANAGE_TEAMS,
    positions: permissions.MANAGE_POSITIONS,
    levels: permissions.MANAGE_LEVELS,
    time_off_policies: permissions.MANAGE_TIME_OFF_POLICIES,
    shifts: permissions.VIEW_SHIFT_SCHEDULES,
    schedules: permissions.VIEW_SHIFT_SCHEDULES,
    announcements: permissions.MANAGE_ANNOUNCEMENTS,
    activities: permissions.VIEW_EMPLOYEES_ACTIVITIES,
  };
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };

  useEffect(() => {
    const hasAnyPermission = Object.keys(tabPermissions).some(
      (tab) => tabPermissions[tab]
    );
    if (hasAnyPermission) {
      getRequest(`/people/configs`, setIsLoading, (response) => {
        setConfigs(response.configs);
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(configs).length > 0) {
      const firstTab = Object.keys(tabPermissions).find((tab) => {
        return tabPermissions[tab];
      });
      const tabNames = searchParams.get("activeTab") || firstTab;
      activateTab(tabNames);
    }
  }, [configs]);

  useEffect(() => {
    const tabName = searchParams.get("activeTab");
    if (tabName && Object.keys(configs).length > 0) {
      activateTab(tabName);
    }
  }, [searchParams]);

  const activateTab = async (key) => {
    setActiveTab(key);

    if (key === "overview" && !fetchStatus.overview) {
      const include_descendants =
        localStorage.getItem(AppConstants.peopleStatsIncludeDescendantsKey) ||
        "false";
      for (const type_ of configs.people_default_stats_types) {
        await getRequest(
          `/people/overview?include_descendants=${include_descendants}&type=${type_}`,
          null,
          (response) => {
            updateObjectState(setOverview, {
              ...response.results,
            });
            if (!fetchStatus.overview) {
              updateObjectState(setFetchStatus, {
                overview: true,
              });
            }
          }
        );
      }
    }

    if (key === "people" && !fetchStatus.people) {
      getRequest(`/people/employees/1`, setShowSecondLoader, (response) => {
        updateObjectState(setFetchStatus, {
          people: true,
        });
      });
    }

    if (key === "teams" && !fetchStatus.teams) {
      getRequest(`/people/teams`, setShowSecondLoader, (response) => {
        setTeams(response.teams);
        updateObjectState(setFetchStatus, {
          teams: true,
        });
      });
    }

    if (key === "positions" && !fetchStatus.positions) {
      getRequest(`/people/positions`, setShowSecondLoader, (response) => {
        setPositions(response.positions);
        let levelsFetched = fetchStatus.levels;
        if (!fetchStatus.levels) {
          setLevels(response.levels);
          levelsFetched = true;
        }
        updateObjectState(setFetchStatus, {
          positions: true,
          levels: levelsFetched,
        });
      });
    }

    if (key === "levels" && !fetchStatus.levels) {
      getRequest(`/people/levels`, setShowSecondLoader, (response) => {
        setLevels(response.levels);
        updateObjectState(setFetchStatus, {
          levels: true,
        });
      });
    }

    if (key === "time_off_policies" && !fetchStatus.timeOffPolicies) {
      getRequest(
        `/people/time-off-policies`,
        setShowSecondLoader,
        (response) => {
          setTimeOffPolicies(response.time_off_policies);
          setTimeOffApprovalWorkflows(response.time_off_approval_workflows);
          setWorkflowApprovalPositions(response.workflow_approval_positions);
          updateObjectState(setFetchStatus, {
            timeOffPolicies: true,
          });
        }
      );
    }

    if (key === "shifts" && !fetchStatus.shifts) {
      getRequest(`/people/shifts`, setShowSecondLoader, (response) => {
        setShifts(response.shifts);
        updateObjectState(setFetchStatus, {
          shifts: true,
        });
      });
    }

    if (key === "schedules" && !fetchStatus.schedules) {
      const getShifts = shifts.length === 0 && !fetchStatus.shifts;
      const getTeams = !fetchStatus.teams;
      getRequest(
        `/people/schedules?getShifts=${getShifts ? "yes" : "no"}&getTeams=${
          getTeams ? "yes" : "no"
        }`,
        setShowSecondLoader,
        (response) => {
          setSchedules(response.schedules);
          if (getShifts) {
            setShifts(response.shifts);
          }
          if (getTeams) {
            setTeams(response.teams);
          }
          updateObjectState(setFetchStatus, {
            schedules: true,
            shifts: getShifts,
            teams: getTeams,
          });
        }
      );
    }

    if (key === "announcements" && !fetchStatus.announcements) {
      getRequest(`/people/announcements`, setShowSecondLoader, (response) => {
        setAnnouncements(response.announcements);
        updateObjectState(setFetchStatus, {
          announcements: true,
        });
      });
    }

    if (key === "activities" && !fetchStatus.activities) {
      // No need to make a request because the component will make the request on render
      updateObjectState(setFetchStatus, {
        activities: true,
      });
    }

    if (key === "attendance" && !fetchStatus.attendance) {
      // No need to make a request because the component will make the request on render
      updateObjectState(setFetchStatus, {
        attendance: true,
      });
    }
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags title={`People | ${toTitleCase(activeTab)}`} />
          <Tabs
            activeKey={activeTab}
            onSelect={(key) => updateQueryParam("activeTab", key)}
            className="mb-3"
            variant={"underline"}
          >
            {permissions.VIEW_EMPLOYEES_STATS && (
              <Tab eventKey="overview" title="Overview">
                <PeopleStats overview={overview} />
              </Tab>
            )}

            {permissions.VIEW_EMPLOYEE_DATA && (
              <Tab eventKey="people" title="People">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <div>
                      {fetchStatus.people && (
                        <ManagePeople
                          employees={employees}
                          setEmployees={setEmployees}
                          config={configs}
                          putRequest={putRequest}
                          getRequest={getRequest}
                          loaderCallback={setShowSecondLoader}
                          permissions={permissions}
                          peoplePagingInfo={peoplePagingInfo}
                          setPeoplePagingInfo={setPeoplePagingInfo}
                          patchRequest={patchRequest}
                        />
                      )}
                    </div>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_TEAMS && (
              <Tab eventKey="teams" title={labelsOverride.teams || "Teams"}>
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageTeams
                        teams={teams}
                        setTeams={setTeams}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        loaderCallback={setShowSecondLoader}
                        permissions={permissions}
                        setData={setData}
                        setShowAppOffCanvas={setShowAppOffCanvas}
                        config={configs}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_POSITIONS && (
              <Tab eventKey="positions" title="Positions">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManagePositions
                        positions={positions}
                        setPositions={setPositions}
                        permissions={permissions}
                        getRequest={getRequest}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        levels={flattenArrayWithChildren(levels)}
                        config={configs}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_LEVELS && (
              <Tab eventKey="levels" title="Levels">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageLevels
                        levels={levels}
                        permissions={permissions}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        setLevels={setLevels}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_TIME_OFF_POLICIES && (
              <Tab eventKey="time_off_policies" title="Time Off Policies">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageTimeOffPolicies
                        timeOffPolicies={timeOffPolicies}
                        permissions={permissions}
                        config={configs}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        setTimeOffPolicies={setTimeOffPolicies}
                        loaderCallback={setShowSecondLoader}
                        positions={workflowApprovalPositions}
                        approvalWorkflows={timeOffApprovalWorkflows}
                        setApprovalWorkflows={setTimeOffApprovalWorkflows}
                        deleteRequest={deleteRequest}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_SHIFT_SCHEDULES && (
              <Tab title={"Shifts"} eventKey="shifts">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageShifts
                        shifts={shifts}
                        setShifts={setShifts}
                        permissions={permissions}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        loaderCallback={setShowSecondLoader}
                        configs={configs}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_SHIFT_SCHEDULES && (
              <Tab title={"Schedules"} eventKey="schedules">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageSchedules
                        schedules={schedules}
                        setSchedules={setSchedules}
                        shifts={shifts}
                        permissions={permissions}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        loaderCallback={setShowSecondLoader}
                        configs={{
                          ...configs,
                          shifts,
                          teams,
                        }}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {permissions.MANAGE_ANNOUNCEMENTS && (
              <Tab title={"Announcements"} eventKey="announcements">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <div>
                      <ManageAnnouncements
                        announcements={announcements}
                        setAnnouncements={setAnnouncements}
                        permissions={permissions}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        loaderCallback={setShowSecondLoader}
                      />
                    </div>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_ATTENDANCE && (
              <Tab title={"Attendance"} eventKey="attendance">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <div>
                      {fetchStatus.attendance && (
                        <EmployeesAttendanceView
                          attendancesEndpoint={`/people/attendances`}
                          statsEndpoint={`/people/attendance/stats`}
                          subTitle={`Attendance for all employees in the "${contextState?.organization?.name}" organization`}
                          getRequest={getRequest}
                        />
                      )}
                    </div>
                  }
                />
              </Tab>
            )}

            {permissions.VIEW_EMPLOYEES_ACTIVITIES && (
              <Tab title={"Activities"} eventKey="activities">
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <div>
                      {fetchStatus.activities && (
                        <EmployeesActivities
                          permissions={permissions}
                          fetchDataEndpoint={"/people/activities"}
                          setIsLoading={setShowSecondLoader}
                          setSecondLoader={setShowSecondLoader}
                        />
                      )}
                    </div>
                  }
                />
              </Tab>
            )}
          </Tabs>

          <AppOffCanvasLocal
            showOffCanvas={showAppOffCanvas}
            setShowOffCanvas={setShowAppOffCanvas}
            title={data.title}
            subtitle={data.subtitle}
            component={
              <>
                <UpdateMembersForm
                  data={data}
                  setData={setData}
                  onSubmit={() => {
                    patchRequest(
                      `/people/teams/assignments/${data.team_id}`,
                      setShowSecondLoader,
                      (response) => {
                        const currentTeams = teams;
                        currentTeams[data.index] = response.team;
                        setTeams(currentTeams);
                      },
                      { ...data }
                    );
                    setShowAppOffCanvas(false);
                  }}
                />
              </>
            }
          />
        </div>
      }
    />
  );
}

function PeopleManagement() {
  return <DashboardPageContainer PageComponent={PeopleManagementPage} />;
}

export default PeopleManagement;
