import React, { useContext, useEffect, useState } from "react";
import { ConditionalView, LoadingView } from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { apiRequest } from "../utils/apiRequests";
import { useNavigate } from "react-router-dom";
import { useAppModal, useToast } from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { AppRoutes } from "../utils/appRoutes";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Employee from "./Forms/Employee";
import { EmployeeLargeCard } from "./components/EmployeeCards";
import { OrganizationCard } from "./components/OrganizationCard";
import {
  FaBullhorn,
  FaCalendarWeek,
  FaChevronRight,
  FaUserClock,
} from "react-icons/fa";
import { FaCalendarDays } from "react-icons/fa6";
import Announcement from "./components/Shared/Announcement";
import ShiftScheduleCard from "./components/Shared/ShiftScheduleCard";
import { AppConstants } from "../utils/appConstants";
import HolidayCard from "./components/Shared/HolidayCard";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { appConfigs } from "../configs";

function HomePageContainer({ getRequest }) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);
  const { showAppModal } = useAppModal();

  useEffect(() => {
    if (!contextState.authenticated) return;
    getOverviewInfo();
  }, []);

  const [organization, setOrganization] = useState({});
  const [configs, setConfigs] = useState({});
  const [next7Days, setNext7Days] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [employee, setEmployee] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getOverviewInfo = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(`/user/home`);
    if (success) {
      if (response.is_admin && !response.registration_complete) {
        showToast({
          title: "Complete Registration",
          message: "Please complete registering your organization.",
          success: true,
        });
        navigate(AppRoutes.Registration.path);
      } else if (!response.is_admin && !response.registration_complete) {
        showAppModal({
          title: "Incomplete Organization Registration",
          component: (
            <h6>
              Your organization has not been fully onboarded yet. Please talk to
              your admin for more information. You cannot access the system
              until your organization is fully onboarded.
            </h6>
          ),
          truthyText: "Sign Out",
          falsyText: "Close",
          truthyFunction: () => {
            getRequest("/user/signout");
          },
        });
        navigate(AppRoutes.Index.path);
      } else {
        const authParams = response.user_auth_params;
        setOrganization(authParams.organization);
        setEmployee(authParams.employee);
        updateContextState({
          ...authParams,
        });
      }

      if (response.user_auth_params.employee) {
        getRequest(`/employee/home-info`, setIsLoading, (response) => {
          setAnnouncements(response.announcements);
          setSchedules(response.schedules);
          setHolidays(response.holidays);
          setNext7Days(response.seven_days_events);
          setConfigs(response.configs);
        });
      }
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={""}>
          <PageMetaTags title={"Home"} />
          {/*<OrganizationCard organization={organization} />*/}
          <div className={"my-4"}>
            <EmployeeLargeCard employee={employee} />
          </div>

          {configs.is_time_off_approver && (
            <div className={"mb-4 d-flex justify-content-end"}>
              <Button
                variant={"primary"}
                onClick={() => {
                  navigate(AppRoutes.TimeOffRequestsReview.path);
                }}
              >
                <FaUserClock className={"me-2"} />
                Time Off Requests Review
                <FaChevronRight className={"ms-2"} />
              </Button>
            </div>
          )}
          <Row>
            <Col lg={4}>
              <Card>
                <Card.Header>
                  <Card.Title>Next 7 Days</Card.Title>
                </Card.Header>
                <Card.Body className={"py-4"}>
                  <ConditionalView
                    condition={Object.keys(next7Days).length > 0}
                    trueView={
                      <div>
                        {configs?.seven_days_events_days_keys?.map((day, i) => {
                          const events = next7Days[day];
                          return (
                            <Card key={i} className={"mb-4"}>
                              <Card.Header>
                                <Card.Title>{day}</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                {events.map((event, j) => {
                                  return (
                                    <ul key={j}>
                                      <li>{event}</li>
                                    </ul>
                                  );
                                })}
                              </Card.Body>
                            </Card>
                          );
                        })}
                      </div>
                    }
                    falseView={
                      <div className={"my-auto"}>
                        <FaCalendarWeek
                          className={"mx-auto d-block"}
                          size={60}
                        />
                        <p className={"text-center mt-3"}>
                          No upcoming items for the next 7 days
                        </p>
                      </div>
                    }
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <Card.Header>
                  <Card.Title>Next 7 Days Schedule</Card.Title>
                </Card.Header>

                <Card.Body className={"py-4"}>
                  <ConditionalView
                    condition={schedules.length > 0}
                    trueView={
                      <div>
                        {schedules.map((schedule, i) => {
                          return (
                            <div key={i} className={"mb-4"}>
                              <ShiftScheduleCard schedule={schedule} key={i} />
                            </div>
                          );
                        })}
                      </div>
                    }
                    falseView={
                      <div className={"my-auto"}>
                        <FaCalendarWeek
                          className={"mx-auto d-block"}
                          size={60}
                        />
                        <p className={"text-center mt-3"}>
                          No schedule for the next 7 days
                        </p>
                      </div>
                    }
                  />
                </Card.Body>
                <Card.Footer className={"text-center"}>
                  <Card.Text>
                    Please use the {appConfigs.appName} mobile app to clock in
                    and out.
                  </Card.Text>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <Card.Header>
                  <Card.Title>Announcements</Card.Title>
                </Card.Header>
                <Card.Body className={"py-4"}>
                  <ConditionalView
                    condition={announcements.length > 0}
                    trueView={
                      <div>
                        {announcements.map((announcement, i) => {
                          return (
                            <div key={i} className={"mb-5"}>
                              <Announcement announcement={announcement} />
                            </div>
                          );
                        })}
                      </div>
                    }
                    falseView={
                      <div className={"my-auto"}>
                        <FaBullhorn className={"mx-auto d-block"} size={60} />
                        <p className={"text-center mt-3"}>No announcements</p>
                      </div>
                    }
                  />
                </Card.Body>
              </Card>
              <Card className={"mt-4"}>
                <Card.Header>
                  <Card.Title>Holidays</Card.Title>
                </Card.Header>
                <Card.Body className={"py-4"}>
                  <ConditionalView
                    condition={holidays.length > 0}
                    trueView={
                      <div>
                        {holidays.map((holiday, i) => {
                          return (
                            <div key={i} className={"mb-4"}>
                              <HolidayCard
                                holiday={holiday}
                                policy={
                                  configs?.holiday_time_off_policies[
                                    holiday.time_off_policy_id
                                  ]
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    }
                    falseView={
                      <div className={"my-auto"}>
                        <FaCalendarDays
                          className={"mx-auto d-block"}
                          size={60}
                        />
                        <p className={"text-center mt-3"}>No holidays</p>
                      </div>
                    }
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      }
    />
  );
}

function Home() {
  return <DashboardPageContainer PageComponent={HomePageContainer} />;
}

export default Home;
