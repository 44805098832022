import React, { useCallback, useContext, useEffect, useState } from "react";
// import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import Registration from "./pages/Registration";
import { AppRoutes } from "./utils/appRoutes";
import SignIn from "./pages/SignIn";
import { apiRequest } from "./utils/apiRequests";
import { AppContext } from "./utils/components/AppContext";
import Admin from "./pages/Admin";
import PeopleManagement from "./pages/PeopleManagement";
import EmployeeManagement from "./pages/EmployeeManagement";
import PayrollManagement from "./pages/PayrollManagement";
import RecruitmentManagement from "./pages/RecruitmentManagement";
import FinanceManagement from "./pages/FinanceManagement";
import OrganizationChartComponent from "./pages/OrganizationChart";
import TimeOffRequestsReview from "./pages/TimeOffRequestsReview";
import SchedulePage from "./pages/SchedulePage";
import MyProfilePage from "./pages/MyProfile";
import OrganizationTreePage from "./pages/OrganizationTreePage";
import IndexPage from "./pages/IndexPage";
import { useApi } from "./hooks/useApi";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ManagersHubPage from "./pages/ManagersHubPage";
import SupervisorTeamViewPage from "./pages/SupervisorTeamViewPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import GoSLIndexPage from "./pages/gosl/GoslIndexPage";
import { appConfigs } from "./configs";
import BulkAddEmployeesPage from "./pages/BulkAddEmployeesPage";
import { Image, Spinner } from "react-bootstrap";
import ContactCardDownload from "./pages/ContactCardDownload";
import StaffMainPage from "./pages/Staff/StaffMainPage";
import StaffOrganizationViewPage from "./pages/Staff/StaffOrganizationViewPage";
import SubscriptionExpiredPage from "./pages/SubscriptionExpiredPage";
import OrganogramPage from "./pages/Organogram";

function App() {
  const { contextState, updateContextState } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const checkAuth = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(`/user/authn`);
    updateContextState({
      authenticated: success,
      darkMode: localStorage.getItem("theme") === "dark",
      ...response,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (isLoading) {
    return (
      <div
        className={`text-center min-vh-100 d-flex align-items-center justify-content-center flex-column`}
      >
        <Spinner animation="grow" role="status">
          <Image
            src={appConfigs.primaryLogo}
            alt={`${appConfigs.appName} logo`}
            height={"45"}
            width={"45"}
            className="align-self-center"
          />
        </Spinner>
        <div className={"mt-4"}>
          <small>Loading...</small>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        {/*<Route exact path={AppRoutes.Index.path} element={<IndexPage />} />*/}
        <Route
          exact
          path={AppRoutes.Index.path}
          element={
            appConfigs.IndexPage ? <appConfigs.IndexPage /> : <IndexPage />
          }
        />
        <Route exact path={AppRoutes.Home.path} element={<Home />} />
        <Route exact path={AppRoutes.SignUp.path} element={<SignUp />} />
        <Route exact path={AppRoutes.SignIn.path} element={<SignIn />} />
        <Route
          exact
          path={AppRoutes.Registration.path}
          element={<Registration />}
        />
        <Route exact path={AppRoutes.Admin.path} element={<Admin />} />
        <Route
          exact
          path={AppRoutes.People.path}
          element={<PeopleManagement />}
        />
        <Route
          exact
          path={AppRoutes.ManagersHub.path}
          element={<ManagersHubPage />}
        />
        <Route
          exact
          path={AppRoutes.TeamSupervisorView.path}
          element={<SupervisorTeamViewPage />}
        />
        <Route
          exact
          path={AppRoutes.Employee.path}
          element={<EmployeeManagement />}
        />
        <Route
          exact
          path={AppRoutes.Payroll.path}
          element={<PayrollManagement />}
        />
        <Route
          exact
          path={AppRoutes.Recruitment.path}
          element={<RecruitmentManagement />}
        />
        <Route
          exact
          path={AppRoutes.Finance.path}
          element={<FinanceManagement />}
        />
        <Route
          exact
          path={AppRoutes.OrgChart.path}
          element={<OrganogramPage />}
        />
        <Route
          exact
          path={AppRoutes.OrgTree.path}
          element={<OrganizationTreePage />}
        />
        <Route
          exact
          path={AppRoutes.MyProfile.path}
          element={<MyProfilePage />}
        />
        <Route
          exact
          path={AppRoutes.TimeOffRequestsReview.path}
          element={<TimeOffRequestsReview />}
        />
        <Route
          exact
          path={AppRoutes.Schedule.path}
          element={<SchedulePage />}
        />
        <Route
          exact
          path={AppRoutes.TermsOfService.path}
          element={<TermsOfServicePage />}
        />
        <Route
          exact
          path={AppRoutes.PrivacyPolicy.path}
          element={<PrivacyPolicyPage />}
        />
        <Route
          exact
          path={AppRoutes.PasswordReset.path}
          element={<PasswordResetPage />}
        />
        <Route
          exact
          path={AppRoutes.PasswordChange.path}
          element={<PasswordResetPage isEmailEntry={false} />}
        />
        <Route
          exact
          path={AppRoutes.BulkAddEmployees.path}
          element={<BulkAddEmployeesPage />}
        />
        <Route exact path={"/contacts/:id"} element={<ContactCardDownload />} />
        <Route exact path={AppRoutes.Staff.path} element={<StaffMainPage />} />
        <Route
          exact
          path={AppRoutes.SubscriptionExpired.path}
          element={<SubscriptionExpiredPage />}
        />
        <Route
          exact
          path={AppRoutes.StaffOrgView.path}
          element={<StaffOrganizationViewPage />}
        />
      </Routes>
    </Router>
  );
}

export default App;
