import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Card, Offcanvas } from "react-bootstrap";
import LeftNavbar from "./LeftNavbar";
import TopNavbar from "./TopNavbar";
import AppToasts from "../../utils/components/AppToasts";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppModal, useOffCanvas, useToast } from "../../utils/functions";
import { AppContext } from "../../utils/components/AppContext";
import { AppRoutes } from "../../utils/appRoutes";
import AppOffCanvas from "../../utils/components/AppOffCanvas";
import AppModal from "../../utils/components/AppModal";
import ThemeSwitch from "../../utils/components/ThemeSwitch";
import { apiRequest } from "../../utils/apiRequests";
import { OffCanvasContext } from "../../utils/components/OffCanvasContext";
import { ConditionalView } from "../../utils/components";

function DashboardPageContainer({
  pageComponent = null,
  PageComponent = null,
}) {
  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
  }, []);

  const [headerBkgColor, setHeaderBkgColor] = useState("bg-transparent");
  const [showSidebar, setShowSidebar] = useState(false);

  const navigate = useNavigate();
  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext);
  const location = useLocation();
  const scrollRef = useRef(null);

  const handleScroll = () => {
    // set header background color when scroll is at top and scrolling
    if (scrollRef.current.scrollTop > 0) {
      setHeaderBkgColor("bg-body");
    } else {
      setHeaderBkgColor("bg-transparent");
    }
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;

    // Attach the scroll event listener to the scrolling element
    scrollContainer.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRef, contextState.darkMode]);

  useEffect(() => {
    if (contextState.authenticated === false) {
      navigate(AppRoutes.SignIn.path, {
        state: { targetPath: `${location.pathname}${location.search}` },
      });
    }
  }, [contextState.authenticated]);

  useEffect(() => {
    if (!contextState.authenticated) return;
    if (
      contextState.employee &&
      Object.keys(contextState.employee).length === 0
    ) {
      getRequest(
        "/user/employee/info",
        () => {},
        (response) => {
          updateContextState({
            ...response.user_auth_params,
          });
        }
      );
    }
  }, [contextState.authenticated]);

  const toggleSidebar = () => setShowSidebar(!showSidebar);

  const getRequest = async (
    endpoint,
    loadingCallback,
    onSuccess,
    alert = false
  ) => {
    if (loadingCallback) loadingCallback(true);

    const { success, response } = await apiRequest.get(`${endpoint}`);
    if (success) {
      onSuccess(response);
      if (alert) {
        showToast({
          message: response.message,
          success: true,
        });
      }
    } else if (endpoint !== "/user/signout") {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    if (loadingCallback) loadingCallback(false);
  };

  const putRequest = async (
    endpoint,
    loadingCallback,
    onSuccess = () => {},
    vals = null,
    multipart = false,
    updateOffCanvas = true
  ) => {
    if (updateOffCanvas) {
      closeOffCanvas();
    }
    if (loadingCallback) loadingCallback(true);
    const { success, response } = await apiRequest.put(
      endpoint,
      vals,
      multipart
    );
    if (success) {
      onSuccess(response);
      showToast({
        message: response.message,
        success: true,
      });
    } else {
      if (updateOffCanvas) {
        restoreOffCanvas();
      }
      showToast({
        title: "Error Updating Info",
        message: response.message,
      });
    }
    if (loadingCallback) loadingCallback(false);
  };

  const patchRequest = async (
    endpoint,
    loadingCallback,
    onSuccess = () => {},
    vals = null,
    multipart = false,
    updateOffCanvas = true
  ) => {
    if (updateOffCanvas) {
      closeOffCanvas();
    }
    if (loadingCallback) loadingCallback(true);
    const { success, response } = await apiRequest.patch(
      endpoint,
      vals,
      multipart
    );
    if (success) {
      onSuccess(response);
      showToast({
        message: response.message,
        success: true,
      });
    } else {
      if (updateOffCanvas) {
        restoreOffCanvas();
      }
      showToast({
        title: "Error Updating Info",
        message: response.message,
      });
    }
    if (loadingCallback) loadingCallback(false);
  };

  const postRequest = async (
    endpoint,
    loadingCallback,
    onSuccess = () => {},
    vals = null,
    multipart = false,
    updateOffCanvas = true
  ) => {
    if (updateOffCanvas) {
      closeOffCanvas();
    }
    if (loadingCallback) loadingCallback(true);
    const { success, response } = await apiRequest.post(
      endpoint,
      vals,
      multipart
    );
    if (success) {
      onSuccess(response);
      showToast({
        message: response.message,
        success: true,
      });
    } else {
      if (updateOffCanvas) {
        restoreOffCanvas();
      }
      showToast({
        title: "Error Updating Info",
        message: response.message,
      });
    }
    if (loadingCallback) loadingCallback(false);
  };

  const deleteRequest = async (
    endpoint,
    loadingCallback,
    onSuccess,
    alert = true
  ) => {
    if (loadingCallback) loadingCallback(true);

    const { success, response } = await apiRequest.delete(`${endpoint}`);
    if (success) {
      onSuccess(response);
      if (alert) {
        showToast({
          message: response.message,
          success: true,
        });
      }
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    if (loadingCallback) loadingCallback(false);
  };

  return (
    <>
      <Container fluid={true} className={"bg-primary bg-opacity-10"}>
        <Row className={"gap-0"}>
          <Col
            lg={2}
            className="d-none d-lg-flex flex-column vh-100 overflow-auto g-0"
          >
            <LeftNavbar permissions={contextState.permissions} />
          </Col>

          <Col
            lg={10}
            ref={scrollRef}
            className="d-flex flex-column vh-100 overflow-auto g-0"
          >
            <div className="sticky-top">
              <TopNavbar
                getRequest={getRequest}
                bkgColor={headerBkgColor}
                toggleSidebar={toggleSidebar}
              />
            </div>

            <div className={"mt-5 mx-3"}>
              <ConditionalView
                condition={pageComponent !== null}
                trueView={<div>{pageComponent}</div>}
                falseView={
                  <>
                    <PageComponent
                      getRequest={getRequest}
                      putRequest={putRequest}
                      patchRequest={patchRequest}
                      postRequest={postRequest}
                      deleteRequest={deleteRequest}
                    />
                  </>
                }
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Offcanvas show={showSidebar} onHide={toggleSidebar} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <LeftNavbar permissions={contextState.permissions} />
        </Offcanvas.Body>
      </Offcanvas>
      <AppToasts />
      <AppOffCanvas />
      <AppModal />
    </>
  );
}

export default DashboardPageContainer;
