import {
  Badge,
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
} from "react-bootstrap";
import { EmployeePopover } from "./EmployeeCards";
import {
  FaChevronRight,
  FaEdit,
  FaUserLock,
  FaUsers,
  FaUsersCog,
} from "react-icons/fa";
import React, { useContext } from "react";
import { formatDate } from "../../utils/dateTime";
import InfoCard from "../../utils/components/InfoCard";
import { InfoDisplayTable } from "../../utils/components";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../utils/appRoutes";
import EmployeeHighlightCard from "./Employee/EmployeeHighlightCard";
import { AppContext } from "../../utils/components/AppContext";

export function TeamHighlight({
  team,
  canManage,
  onEditClick,
  onManageMembersClick,
  onManageTimeOffApproversClick,
  onManageExpenseApproversClick,
  onMakePrimaryClick,
  supervisorView,
  useHighlightCard = false,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  const { id, name, description, members, supervisor, is_primary } = team;

  const navigate = useNavigate();

  return (
    <Card className={"mb-4 border-top"}>
      <Card.Header className={"py-4"}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Card.Title as={"h4"} className={"mb-3"}>
              {name}
              {is_primary && (
                <Badge className={"ms-2"} bg={"primary"} pill={true}>
                  Primary {labelsOverride.team || "Team"}
                </Badge>
              )}
            </Card.Title>
            <Card.Subtitle>{description}</Card.Subtitle>
          </div>
          {supervisorView && (
            <div>
              <Button
                variant={"primary"}
                onClick={() => {
                  navigate(AppRoutes.TeamSupervisorView.paramLessPath + id);
                }}
              >
                <FaUsersCog className={"me-2"} /> Supervisor View{" "}
                <FaChevronRight className={"ms-2"} />
              </Button>
            </div>
          )}
        </div>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col sm={6} lg={6}>
            <InfoDisplayTable
              data={{
                "Date Formed": formatDate(team.formed_date),
                [labelsOverride.department || "Department"]:
                  team.department_name || "N/A",
              }}
            />
          </Col>
          <Col sm={6} lg={6}>
            <InfoDisplayTable
              data={{
                Members: `${members.length + 1}`,
                Organization: team.organization_name,
              }}
            />
          </Col>
        </Row>
        <Table striped bordered hover className="mt-4">
          <tbody>
            <tr className={"py-4"}>
              <td>Supervisor / Manager</td>
              <td>
                {useHighlightCard ? (
                  <div className={"d-flex justify-content-start"}>
                    <EmployeeHighlightCard
                      employee={supervisor}
                      limitSize={true}
                    />
                  </div>
                ) : (
                  <OverlayTrigger
                    key={supervisor.id}
                    trigger="click"
                    placement="right"
                    overlay={<EmployeePopover employee={supervisor} />}
                    rootClose
                  >
                    <Button variant="link" className="">
                      {supervisor.full_name}
                    </Button>
                  </OverlayTrigger>
                )}
              </td>
            </tr>
            <tr className={"py-4"}>
              <td>Members</td>
              <td>
                <div className="d-flex flex-wrap flex-row">
                  {members.map((member) => {
                    if (useHighlightCard) {
                      return (
                        <div key={member.id} className={"me-2 mt-2"}>
                          <EmployeeHighlightCard
                            employee={member}
                            limitSize={true}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <OverlayTrigger
                          key={member.id}
                          trigger="click"
                          placement="right"
                          overlay={<EmployeePopover employee={member} />}
                          rootClose
                        >
                          <Button variant="link" className="">
                            {member.full_name}
                          </Button>
                        </OverlayTrigger>
                      );
                    }
                  })}
                </div>
              </td>

              {canManage && (
                <td className={"d-flex justify-content-end"}>
                  <Button
                    onClick={() => {
                      onManageMembersClick(members);
                    }}
                    variant="secondary"
                    className="me-2"
                  >
                    <FaUsersCog className={"me-2"} />
                    Manage
                  </Button>
                </td>
              )}
            </tr>
            {/*<tr>*/}
            {/*  <td>Time Off Approvers</td>*/}
            {/*  <td>*/}
            {/*    {time_off_approvers.map((approver) => (*/}
            {/*      <OverlayTrigger*/}
            {/*        key={approver.id}*/}
            {/*        trigger="click"*/}
            {/*        placement="right"*/}
            {/*        overlay={<EmployeePopover employee={approver} />}*/}
            {/*        rootClose*/}
            {/*      >*/}
            {/*        <Button variant="link" className="">*/}
            {/*          {approver.full_name}*/}
            {/*        </Button>*/}
            {/*      </OverlayTrigger>*/}
            {/*    ))}*/}
            {/*  </td>*/}

            {/*{canManage && (*/}
            {/*  <td className={"d-flex justify-content-end"}>*/}
            {/*    <Button*/}
            {/*      onClick={() => {*/}
            {/*        onManageTimeOffApproversClick(time_off_approvers);*/}
            {/*      }}*/}
            {/*      variant="secondary"*/}
            {/*      className="me-2"*/}
            {/*    >*/}
            {/*      <FaUsersCog className={"me-2"} />*/}
            {/*      Manage*/}
            {/*    </Button>*/}
            {/*  </td>*/}
            {/*)}*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>Expense Approvers</td>*/}
            {/*  <td>*/}
            {/*    {expense_approvers.map((approver) => (*/}
            {/*      <OverlayTrigger*/}
            {/*        key={approver.id}*/}
            {/*        trigger="click"*/}
            {/*        placement="right"*/}
            {/*        overlay={<EmployeePopover employee={approver} />}*/}
            {/*        rootClose*/}
            {/*      >*/}
            {/*        <Button variant="link" className="">*/}
            {/*          {approver.full_name}*/}
            {/*        </Button>*/}
            {/*      </OverlayTrigger>*/}
            {/*    ))}*/}
            {/*  </td>*/}

            {/*  {canManage && (*/}
            {/*    <td className={"d-flex justify-content-end"}>*/}
            {/*      <Button*/}
            {/*        onClick={() => {*/}
            {/*          onManageExpenseApproversClick(expense_approvers);*/}
            {/*        }}*/}
            {/*        variant="secondary"*/}
            {/*        className="me-2"*/}
            {/*      >*/}
            {/*        <FaUsersCog className={"me-2"} />*/}
            {/*        Manage*/}
            {/*      </Button>*/}
            {/*    </td>*/}
            {/*  )}*/}
            {/*</tr>*/}
          </tbody>
        </Table>
      </Card.Body>
      {canManage && (
        <Card.Footer>
          <div className="d-flex justify-content-end py-3">
            {onEditClick && (
              <Button
                onClick={() => {
                  onEditClick();
                }}
                variant={"outline-primary"}
                className="me-2"
              >
                <FaEdit className={"me-2"} />
                Edit {labelsOverride.team || "Team"} Info
              </Button>
            )}
          </div>
        </Card.Footer>
      )}
      {!team.is_primary && onMakePrimaryClick && (
        <Card.Footer className="d-flex justify-content-end py-3">
          <Button
            onClick={() => {
              onMakePrimaryClick();
            }}
            variant={"outline-danger"}
            className="me-2"
          >
            <FaUsers className={"me-2"} />
            Make Primary {labelsOverride.team || "Team"}
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
}
