import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { isEmpty, toTitleCase } from "../utils/functions";
import { getUTCDateTime } from "../utils/dateTime";
import ImageCropInput from "./Forms/Shared/ImageCropInput";

export const FormFieldTypes = {
  TEXT: "text",
  EMAIL: "email",
  PASSWORD: "password",
  NUMBER: "number",
  DATE: "date",
  TEL: "tel",
  URL: "url",
  SEARCH: "search",
  COLOR: "color",
  RANGE: "range",
  FILE: "file",
  HIDDEN: "hidden",
  MONTH: "month",
  WEEK: "week",
  TIME: "time",
  DATETIME_LOCAL: "datetime-local",
  IMAGE: "image",
  TEXTAREA: "textarea",
  STRING_SELECT: "stringSelect",
  OBJECT_SELECT: "objectSelect",
  STRING_CHECKBOX: "stringCheckbox",
  OBJECT_CHECKBOX: "objectCheckbox",
  STRING_RADIO: "stringRadio",
  OBJECT_RADIO: "objectRadio",
  MULTI_STRING_SELECT: "multiStringSelect",
  MULTI_OBJECT_SELECT: "multiObjectSelect",
  SWITCH: "switch",
  PDF: "pdf",
};

const FormLabel = ({ labelName, valueKey, info, isRequired, hideLabel }) => {
  if (hideLabel) return null;
  return (
    <>
      <Form.Label>
        {labelName || toTitleCase(valueKey)}
        <span className={isRequired ? "text-danger" : "text-muted"}>
          {isRequired ? "* (required)" : " (optional)"}
        </span>
      </Form.Label>
      {info && (
        <Form.Text as={"p"} className="text-muted">
          <small>{info}</small>
        </Form.Text>
      )}
    </>
  );
};

const STR_SELECT_TYPES = [
  FormFieldTypes.STRING_SELECT,
  FormFieldTypes.STRING_CHECKBOX,
  FormFieldTypes.STRING_RADIO,
];

const OBJ_SELECT_TYPES = [
  FormFieldTypes.OBJECT_SELECT,
  FormFieldTypes.OBJECT_CHECKBOX,
  FormFieldTypes.OBJECT_RADIO,
];

export const FormFields = ({
  type = "text",
  labelName,
  state,
  setState,
  valueKey,
  info,
  validation,
  errorMsg,
  optionsList,
  optionsLabelKey = "name",
  optionsValueKey = "id",
  isRequired = true,
  hideLabel = false,
  imageRounded = false,
  imageWidth = 100,
  maxFileMb = 0.3,
  extraComponent,
  disabled = false,
}) => {
  // first 17 values from from FormFieldTypes
  const BASIC_TYPES = Object.values(FormFieldTypes).slice(0, 16);

  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (isRequired || !isEmpty(state[valueKey])) {
      if (validation) {
        setIsValid(validation(state[valueKey]));
      } else if (type === FormFieldTypes.DATE) {
        const date = getUTCDateTime(state[valueKey]);
        setIsValid(state[valueKey] ? date.isValid() : false);
      } else if (STR_SELECT_TYPES.includes(type)) {
        setIsValid(optionsList.includes(state[valueKey]));
      } else if (OBJ_SELECT_TYPES.includes(type)) {
        const isValid = optionsList.some(
          (option) => option[optionsValueKey] === state[valueKey]
        );
        setIsValid(isValid);
      } else {
        setIsValid(!isEmpty(state[valueKey]));
      }
    }
  }, [state[valueKey], optionsList]);

  useEffect(() => {
    setState((prevState) => {
      const prevValidations = prevState.validations || {};
      const newValidations = {
        ...prevValidations,
        [valueKey]: isValid,
      };
      return {
        ...prevState,
        validations: newValidations,
      };
    });
  }, [isValid]);

  if (type === FormFieldTypes.SWITCH) {
    return (
      <Card body={true} className="mb-4">
        <Form.Group
          controlId={valueKey}
          className={"d-flex justify-content-between align-items-center"}
        >
          <FormLabel
            labelName={labelName}
            valueKey={valueKey}
            isRequired={isRequired}
            hideLabel={hideLabel}
          />
          <Form.Switch
            name={valueKey}
            checked={state[valueKey] || false}
            disabled={disabled}
            readOnly={disabled}
            onChange={(e) =>
              setState({ ...state, [valueKey]: e.target.checked })
            }
          />
        </Form.Group>
        {info && <small className="text-muted">{info}</small>}
      </Card>
    );
  }

  return (
    <Form.Group controlId={valueKey} className={"mb-4"}>
      <FormLabel
        labelName={labelName}
        valueKey={valueKey}
        info={info}
        isRequired={isRequired}
        hideLabel={hideLabel}
      />

      {BASIC_TYPES.includes(type) && (
        <Form.Control
          type={type}
          name={valueKey}
          required={isRequired}
          value={state[valueKey] || ""}
          disabled={disabled}
          readOnly={disabled}
          autoComplete={type}
          onChange={(e) => {
            setState({ ...state, [valueKey]: e.target.value });
          }}
        />
      )}

      {type === FormFieldTypes.TEXTAREA && (
        <Form.Control
          as="textarea"
          name={valueKey}
          rows={5}
          required={isRequired}
          value={state[valueKey] || ""}
          disabled={disabled}
          readOnly={disabled}
          onChange={(e) => setState({ ...state, [valueKey]: e.target.value })}
        />
      )}

      {type === FormFieldTypes.STRING_SELECT && (
        <Form.Select
          name={valueKey}
          required={isRequired}
          value={state[valueKey] || ""}
          disabled={disabled}
          readOnly={disabled}
          onChange={(e) => setState({ ...state, [valueKey]: e.target.value })}
        >
          <option value="">Select an option</option>
          {optionsList.map((option) => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            );
          })}
        </Form.Select>
      )}

      {type === FormFieldTypes.OBJECT_SELECT && (
        <Form.Select
          name={valueKey}
          required={isRequired}
          value={state[valueKey] || ""}
          disabled={disabled}
          readOnly={disabled}
          onChange={(e) => setState({ ...state, [valueKey]: e.target.value })}
        >
          <option value="">Select an option</option>
          {optionsList.map((option, index) => (
            <option key={index} value={option[optionsValueKey]}>
              {option[optionsLabelKey]}
            </option>
          ))}
        </Form.Select>
      )}

      {type === FormFieldTypes.STRING_CHECKBOX && (
        <div>
          {optionsList.map((option, index) => {
            return (
              <Form.Check
                key={index}
                type="checkbox"
                label={option}
                name={valueKey}
                value={option}
                disabled={disabled}
                readOnly={disabled}
                onChange={(e) => {
                  const value = e.target.value;
                  const checked = e.target.checked;
                  if (checked) {
                    setState({
                      ...state,
                      [valueKey]: [...state[valueKey], value],
                    });
                  } else {
                    const updated = state[valueKey].filter(
                      (item) => item !== value
                    );
                    setState({ ...state, [valueKey]: updated });
                  }
                }}
              />
            );
          })}
        </div>
      )}

      {type === FormFieldTypes.OBJECT_CHECKBOX && (
        <div>
          {optionsList.map((option, index) => {
            return (
              <Form.Check
                key={index}
                type="checkbox"
                label={option[optionsLabelKey]}
                name={valueKey}
                value={option[optionsValueKey]}
                disabled={disabled}
                readOnly={disabled}
                onChange={(e) => {
                  const value = e.target.value;
                  const checked = e.target.checked;
                  if (checked) {
                    setState({
                      ...state,
                      [valueKey]: [...state[valueKey], value],
                    });
                  } else {
                    const updated = state[valueKey].filter(
                      (item) => item !== value
                    );
                    setState({ ...state, [valueKey]: updated });
                  }
                }}
              />
            );
          })}
        </div>
      )}

      {type === FormFieldTypes.STRING_RADIO && (
        <div>
          {optionsList.map((option, index) => {
            return (
              <Form.Check
                key={index}
                type="radio"
                label={option}
                name={valueKey}
                value={option}
                disabled={disabled}
                readOnly={disabled}
                checked={state[valueKey] === option}
                onChange={(e) =>
                  setState({ ...state, [valueKey]: e.target.value })
                }
              />
            );
          })}
        </div>
      )}

      {type === FormFieldTypes.OBJECT_RADIO && (
        <div>
          {optionsList.map((option, index) => {
            return (
              <Form.Check
                key={index}
                type="radio"
                label={option[optionsLabelKey]}
                name={valueKey}
                value={option[optionsValueKey]}
                checked={state[valueKey] === option[optionsValueKey]}
                disabled={disabled}
                readOnly={disabled}
                onChange={(e) =>
                  setState({ ...state, [valueKey]: e.target.value })
                }
              />
            );
          })}
        </div>
      )}

      {type === FormFieldTypes.IMAGE && (
        <ImageCropInput
          imgSrc={state[`${valueKey}_url`] || state[valueKey]}
          maxMb={maxFileMb}
          disabled={disabled}
          readOnly={disabled}
          onComplete={async (imageFile, imageUrl) => {
            setState((prevState) => ({
              ...prevState,
              [`${valueKey}_url`]: imageUrl,
              [valueKey]: imageFile,
            }));
          }}
          rounded={imageRounded}
          imageWidth={imageWidth}
        />
      )}

      {type === FormFieldTypes.PDF && (
        <Form.Control
          type="file"
          name={valueKey}
          accept=".pdf"
          placeholder={"Upload a PDF file"}
          disabled={disabled}
          readOnly={disabled}
          onChange={(event) => {
            const file = event.target.files[0];
            if (
              file &&
              file.type === "application/pdf" &&
              file.size < maxFileMb * 1000000
            ) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onloadend = () => {
                setState((prevState) => ({
                  ...prevState,
                  [valueKey]: file,
                  [`${valueKey}_url`]: reader.result,
                }));
              };
            } else {
              alert(
                `Invalid file type or size. Please select a pdf file smaller than or equal to ${maxFileMb} MB`
              );
            }
          }}
        />
      )}

      {!isValid && (
        <Form.Text as={"p"} className={"text-danger mt-2"}>
          {errorMsg ||
            `Please ${optionsList ? "select" : "enter"} a valid ${
              labelName || toTitleCase(valueKey)
            }`}
        </Form.Text>
      )}

      {extraComponent && <div className={"mt-3"}>{extraComponent}</div>}
    </Form.Group>
  );
};
