import React, { useContext, useEffect, useState } from "react";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";
import { BsEnvelope, BsLock } from "react-icons/bs";
import { RiLockPasswordLine } from "react-icons/ri";
import logo from "../assets/logos/white-color-outline.png";
import { apiRequest } from "../utils/apiRequests";
import { LoadingView } from "../utils/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppRoutes } from "../utils/appRoutes";
import { useToast } from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { AppConstants } from "../utils/appConstants";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { appConfigs } from "../configs";
import LogoWithText from "./components/Shared/LogoWithText";

const PasswordHint = [
  "Password must be at least 8 characters long",
  "Password must contain at least one uppercase letter",
  "Password must contain at least one lowercase letter",
  "Password must contain at least one digit",
  "Password must contain at least one special character",
  "Special characters allowed are: !@#$%^&*()_+-=,.?;:'\"[]{}|",
  "Password must not contain /, \\, or <>",
];

function SignUp() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const confirmationToken = searchParams.get("token");

  useEffect(() => {
    if (contextState.authenticated) {
      navigate(AppRoutes.Home.path);
    } else {
      console.log("confirmationToken", confirmationToken);
      localStorage.setItem(AppConstants.authTokenKey, confirmationToken);
      getUsersEmail();
    }
  }, [contextState.authenticated]);

  const [data, setData] = useState({
    email: "",
    password: "",
    repeatPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getUsersEmail = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(`/user/register/email`);
    if (success) {
      setData({
        email: response.email,
      });
    } else {
      showToast({
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { success, response } = await apiRequest.patch("/user", data);
    if (success) {
      localStorage.setItem(AppConstants.authTokenKey, response.token);
      updateContextState({ authenticated: true });
      navigate(AppRoutes.Home.path);
    } else {
      showToast({
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const isFormValid = () => {
    return (
      validatePassword(data.password) && data.password === data.repeatPassword
    );
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?!.*[\\/<>\s]).{8,}$/;
    return passwordRegex.test(password);
  };

  return (
    <LoadingView
      isLoading={isLoading}
      addAppToasts={true}
      view={
        <Container fluid={true}>
          <PageMetaTags title={`Sign Up`} />
          <Row className="justify-content-center align-items-center min-vh-100">
            <Col lg={8} lg={6} xl={4}>
              <Card
                className={
                  "w-f00 border-primary border-1 rounded-start shadow-lg"
                }
              >
                <Card.Header className="text-center pt-3">
                  <LogoWithText text={null} />
                  <div className={"my-4"}>
                    <Card.Title as={"h4"}>
                      Complete your Registration
                    </Card.Title>
                    <Card.Subtitle as={"p"}>
                      Create a strong password to secure your account.
                    </Card.Subtitle>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSignUp}>
                    <Form.Group controlId="formBasicEmail" className="mb-4">
                      <Form.Label>Email address</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <BsEnvelope />
                        </span>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          value={data.email || ""}
                          onChange={handleInputChange}
                          disabled={true}
                          autoComplete={"email"}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" className="mb-4">
                      <Form.Label>Password</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <BsLock />
                        </span>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={data.password || ""}
                          autoComplete={"new-password"}
                          onChange={handleInputChange}
                          isInvalid={
                            data.password && !validatePassword(data.password)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          <ul>
                            {PasswordHint.map((hint) => (
                              <li key={hint}>{hint}</li>
                            ))}
                          </ul>
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="formBasicRepeatPassword"
                      className="mb-5"
                    >
                      <Form.Label>Repeat Password</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <RiLockPasswordLine />
                        </span>
                        <Form.Control
                          type="password"
                          name="repeatPassword"
                          placeholder="Repeat Password"
                          value={data.repeatPassword || ""}
                          autoComplete={"new-password"}
                          onChange={handleInputChange}
                          isInvalid={data.repeatPassword !== data.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          Passwords do not match.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={!isFormValid()}
                    >
                      Sign Up
                    </Button>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <div className="py-4 d-flex justify-content-center align-items-center">
                    <a href="/signin">
                      <strong>Have an Account? Sign In</strong>
                    </a>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      }
    />
  );
}

export default SignUp;
