import React, { useContext, useEffect, useState } from "react";
import { LoadingView } from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { toTitleCase, useOffCanvas, useToast } from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { OffCanvasContext } from "../utils/components/OffCanvasContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import { TeamHighlight } from "./components/Team";
import { FaSearch } from "react-icons/fa";
import PageMetaTags from "./components/Shared/PageMetaTags";

export function ManagersHub({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext);

  const { permissions } = contextState;

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterTerm, setFilterTerm] = useState("");
  const [activeTab, setActiveTab] = useState("supervised_teams");
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getRequest(`/managers-hub/teams`, setIsLoading, (response) => {
      setTeams(response.teams);
    });
  }, []);

  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };
  const activateTab = async (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    const tabName = searchParams.get("activeTab");
    if (tabName) {
      activateTab(tabName);
    }
  }, [searchParams]);

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <>
          <PageMetaTags title={`Managers Hub | ${toTitleCase(activeTab)}`} />
          <Tabs
            activeKey={activeTab}
            onSelect={(key) => updateQueryParam("activeTab", key)}
            className="mb-3"
            variant={"underline"}
          >
            <Tab eventKey="supervised_teams" title="Supervised Teams">
              <Card className={"mb-4"}>
                <Card.Header className={"py-4"}>
                  <Card.Title as={"h4"} className={"mb-3"}>
                    Supervised Teams
                  </Card.Title>
                  <Card.Subtitle>
                    These are the teams you directly supervise and the teams
                    that people who report up to you supervise
                  </Card.Subtitle>
                </Card.Header>
              </Card>

              <InputGroup className={"mb-4"}>
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search teams by name or supervisor name"
                  value={filterTerm}
                  onChange={(e) => setFilterTerm(e.target.value)}
                />
              </InputGroup>

              {teams.map((team, index) => {
                if (
                  filterTerm &&
                  !team.name.toLowerCase().includes(filterTerm) &&
                  !team.supervisor.full_name.toLowerCase().includes(filterTerm)
                ) {
                  return null;
                }
                return (
                  <TeamHighlight
                    key={index}
                    team={team}
                    supervisorView={true}
                  />
                );
              })}
            </Tab>
          </Tabs>
        </>
      }
    />
  );
}

function ManagersHubPage() {
  return <DashboardPageContainer PageComponent={ManagersHub} />;
}
export default ManagersHubPage;
