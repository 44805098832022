// Naming pattern should be <product / section><Item name>

import { isValidEmail } from "../../../utils/functions";
import { FormFieldTypes } from "../../FormFields";

const StaffFormsConfigs = {
  organization: [
    {
      valueKey: "name",
      labelName: "Organization Name",
    },
    {
      valueKey: "admin_email",
      labelName: "Org Admin Email",
      errorMsg: "Please enter a valid email address",
      validation: (email) => {
        return isValidEmail(email);
      },
    },
    {
      valueKey: "signed_agreement_file",
      type: FormFieldTypes.PDF,
      imageRounded: true,
      maxFileMb: 5,
      isRequired: false,
    },
  ],
  subscription: [
    {
      valueKey: "description",
      type: FormFieldTypes.TEXTAREA,
    },
    {
      valueKey: "start_date",
      type: FormFieldTypes.DATE,
      info: "This subscription will start at 00:00:00 on the selected date.",
    },
    {
      valueKey: "end_date",
      type: FormFieldTypes.DATE,
      info: "This subscription will end at 00:00:00 on the selected date.",
    },
    {
      valueKey: "available_seats",
      type: FormFieldTypes.NUMBER,
    },
  ],
  subscriptionItem: (
    statusList,
    productBundles,
    bundlesComponent,
    isFirst = false
  ) => {
    return [
      {
        valueKey: "invoice_number",
        type: FormFieldTypes.TEXT,
      },
      {
        valueKey: "currency",
        type: FormFieldTypes.STRING_SELECT,
        optionsList: ["USD", "SLE"],
      },
      {
        valueKey: "normal_price",
        type: FormFieldTypes.NUMBER,
      },
      {
        valueKey: "amount_paid",
        type: FormFieldTypes.NUMBER,
      },
      {
        valueKey: "discount_amount",
        type: FormFieldTypes.NUMBER,
      },
      {
        valueKey: "added_seats",
        type: FormFieldTypes.NUMBER,
        info: "Adding a number here will increase the number of seats available for this subscription by the number entered.",
      },
      {
        valueKey: "invoice_date",
        type: FormFieldTypes.DATE,
      },
      {
        valueKey: "payment_date",
        type: FormFieldTypes.DATE,
      },
      {
        valueKey: "start_date",
        type: FormFieldTypes.DATE,
        info: "This subscription item wii start at 00:00:00 on the selected date.",
        disabled: isFirst,
      },
      {
        valueKey: "end_date",
        type: FormFieldTypes.DATE,
        info: "This subscription item wii end at 00:00:00 on the selected date.",
        disabled: isFirst,
      },
      {
        valueKey: "status",
        type: FormFieldTypes.STRING_SELECT,
        optionsList: statusList,
      },
      {
        valueKey: "bundles",
        valueLabel: "Subscription Bundle",
        info: isFirst
          ? "This item cannot be changed when creating a new subscription."
          : null,
        type: FormFieldTypes.STRING_SELECT,
        optionsList: Object.keys(productBundles),
        disabled: false,
        extraComponent: bundlesComponent,
      },
    ];
  },
};

export { StaffFormsConfigs };
