import React, { useContext, useEffect, useRef, useState } from "react";
import { LoadingView } from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  toTitleCase,
  useAppModal,
  useOffCanvas,
  useToast,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { OffCanvasContext } from "../utils/components/OffCanvasContext";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import EmployeeHighlightCard from "./components/Employee/EmployeeHighlightCard";
import OrganizationChart from "@dabeng/react-orgchart";
import {
  FaCalendar,
  FaDownload,
  FaEnvelope,
  FaPhone,
  FaSitemap,
  FaUser,
} from "react-icons/fa";
import { AppRoutes } from "../utils/appRoutes";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { formatDate, formDate } from "../utils/dateTime";

const EmployeeInfo = ({ employee, onViewClick, isCurrent = false }) => {
  return (
    <Card
      className={
        !isCurrent
          ? `border-0 border-top border-primary border-5 glow-animation`
          : "border-5 border-primary rounded-3"
      }
    >
      <Card.Header className="d-flex flex-column align-items-center py-4">
        <Image
          src={employee.profile_image}
          roundedCircle
          className="mb-3"
          fluid
          height={50}
          width={50}
        />
        <h4 className={"text-center"}>
          {/*(#{employee.employee_number})?*/}
          {employee.full_name}
        </h4>
        <h5 className={"text-muted mt-2"}>{employee.position_title}</h5>
      </Card.Header>
      <Card.Body>
        {employee.work_phone && (
          <p>
            <FaPhone className="me-2 text-primary" />
            {employee.work_phone}
          </p>
        )}
        {employee.work_email && (
          <p>
            <FaEnvelope className="me-2 text-primary" />
            {employee.work_email}
          </p>
        )}
        {employee.position && (
          <p>
            <FaUser className="me-2 text-primary" />
            {employee.position.title}
          </p>
        )}
        {employee.start_date && (
          <p>
            <FaCalendar className="me-2 text-primary" />
            {formatDate(employee.start_date)}
          </p>
        )}

        <div className={"d-flex justify-content-center my-4"}>
          <FaSitemap
            className={`${
              isCurrent ? "text-primary" : "pointer text-muted hover-primary"
            }`}
            size={30}
            onClick={() => {
              if (isCurrent) return;
              onViewClick(employee.id);
            }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export function Organogram({ getRequest }) {
  const navigate = useNavigate();
  const { showAppModal } = useAppModal();
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);

  const { permissions } = contextState;

  useEffect(() => {
    getOrganizationChartInfo();
  }, []);

  const [activeData, setActiveData] = useState({});
  const [collectedData, setCollectedData] = useState({});
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [config, setConfig] = useState({});
  const [chartData, setChartData] = useState(null);
  const [secondaryLoading, setSecondaryLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpaque, setIsOpaque] = useState(false);

  const updateInfo = (response) => {
    const emp_info = response.employee_info;
    setCurrentEmployee(emp_info.employee);
    setActiveData(emp_info);
    setCollectedData({
      ...collectedData,
      [emp_info.employee.id]: emp_info,
    });
  };
  const getOrganizationChartInfo = async () => {
    getRequest(`/organization/org-chart`, setIsLoading, (response) => {
      updateInfo(response);
    });
  };

  const setOrFetch = (employeeId) => {
    if (collectedData[employeeId]) {
      setSecondaryLoading(true);
      setTimeout(() => {
        setActiveData(collectedData[employeeId]);
        setCurrentEmployee(collectedData[employeeId].employee);
        setSecondaryLoading(false);
      }, 1000);
    } else {
      getRequest(
        `/organization/org-chart/${employeeId}`,
        setSecondaryLoading,
        (response) => {
          updateInfo(response);
        }
      );
    }
  };

  useEffect(() => {
    let interval;
    if (secondaryLoading) {
      interval = setInterval(() => {
        setIsOpaque((prev) => !prev);
      }, 750);
    } else {
      setIsOpaque(true); // Ensure full opacity when not loading
    }

    // Clean up the interval when isLoading is false or when component unmounts
    return () => clearInterval(interval);
  }, [secondaryLoading]);

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={`my-5 ${isOpaque ? "opacity-100" : "opacity-50"}`}>
          <PageMetaTags title={`Organization Chart`} />
          {activeData?.supervisory_chain?.map((supervisor, i) => {
            return (
              <Col key={supervisor.id} className="mx-auto col-8 col-lg-3 mb-4">
                <EmployeeInfo employee={supervisor} onViewClick={setOrFetch} />
              </Col>
            );
          })}

          <Card
            body={true}
            className={
              "border-0 border-top border-1 border-secondary mt-3 bg-transparent"
            }
          >
            <h4 className={"mt-4 ms-4 text-primary"}>
              {currentEmployee?.first_name || "Employee"} and Peers
            </h4>
            <Row className="justify-content-center mt-5">
              {activeData?.peers?.map((emp, i) => {
                return (
                  <Col key={emp.id} className="me-lg-2 col-6 col-lg-3 mb-4">
                    <EmployeeInfo
                      employee={emp}
                      isCurrent={emp.id === currentEmployee.id}
                      onViewClick={setOrFetch}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>

          <Card
            body={true}
            className={
              "border-0 border-top border-1 border-secondary mt-3 bg-transparent"
            }
          >
            <h4 className={"mt-4 ms-4 text-primary"}>
              {currentEmployee?.first_name || "Employee"}'s Direct Reports
            </h4>
            <Row className="justify-content-center mt-5">
              {activeData?.reports?.map((emp, i) => {
                return (
                  <Col key={emp.id} className="me-lg-2 col-6 col-lg-3 mb-4">
                    <EmployeeInfo
                      employee={emp}
                      isCurrent={emp.id === currentEmployee.id}
                      onViewClick={setOrFetch}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>
        </div>
      }
    />
  );
}

function OrganogramPage() {
  return <DashboardPageContainer PageComponent={Organogram} />;
}
export default OrganogramPage;
