import React, { useContext, useEffect, useState } from "react";
import { LoadingView } from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  toTitleCase,
  updateObjectState,
  useAppModal,
  useOffCanvas,
  useToast,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { OffCanvasContext } from "../utils/components/OffCanvasContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card, Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import { TeamHighlight } from "./components/Team";
import { FaSearch } from "react-icons/fa";
import PageMetaTags from "./components/Shared/PageMetaTags";
import OutOfPerimeterAttendanceRequests from "./components/ManagersHub/OutOfPerimeterAttendance";
import DefaultEmployeesStats from "./components/Shared/DefaultEmployeesStats";
import EmployeesAttendanceView from "./components/Shared/EmployeesAttendanceView";

export function SupervisorTeamView({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);
  const { showAppModal } = useAppModal();

  const { permissions } = contextState;

  const { teamId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterTerm, setFilterTerm] = useState("");
  const [activeTab, setActiveTab] = useState("overview");
  const [attendancesStats, setAttendancesStats] = useState({});
  const [attendances, setAttendances] = useState([]);
  const [attendanceRequests, setAttendanceRequests] = useState([]);
  const [overview, setOverview] = useState({});
  const [team, setTeam] = useState({});
  const [tabFetchStatuses, setTabFetchStatuses] = useState({
    overview: false,
    attendance_requests: false,
  });
  const [secondLoader, setSecondLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getRequest(`/managers-hub/team/${teamId}`, setIsLoading, (response) => {
      setTeam(response.team);
      setOverview(response.overview);
    });
  }, []);

  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };

  const getAttendanceRequests = () => {
    getRequest(
      `/managers-hub/team/${teamId}/attendance-requests`,
      setSecondLoader,
      (response) => {
        setAttendanceRequests(response.attendance_requests);
        setTabFetchStatuses({
          ...tabFetchStatuses,
          attendance_requests: true,
        });
      }
    );
  };

  const activateTab = async (key) => {
    setActiveTab(key);

    if (
      key === "attendance_requests" &&
      !tabFetchStatuses.attendance_requests
    ) {
      getAttendanceRequests();
    }
    if (key === "attendance" && !tabFetchStatuses.attendance_requests) {
      updateObjectState(setTabFetchStatuses, { attendance_requests: true });
    }
  };

  useEffect(() => {
    const tabName = searchParams.get("activeTab");
    if (tabName) {
      activateTab(tabName);
    }
  }, [searchParams]);

  const attendanceRequestsAction = (requestId, action) => {
    patchRequest(
      `/managers-hub/team/${teamId}/attendance-requests/${requestId}`,
      secondLoader,
      (response) => {
        getAttendanceRequests();
      },
      { action }
    );
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <>
          <PageMetaTags title={`Team | ${toTitleCase(activeTab)}`} />
          {team.id && <TeamHighlight team={team} useHighlightCard={true} />}
          <Tabs
            activeKey={activeTab}
            onSelect={(key) => updateQueryParam("activeTab", key)}
            className="mb-3"
            variant={"underline"}
          >
            <Tab eventKey="overview" title="Overview">
              <DefaultEmployeesStats statsData={overview} />
            </Tab>
            <Tab eventKey="attendance" title="Attendance">
              {tabFetchStatuses.attendance_requests && (
                <EmployeesAttendanceView
                  attendancesEndpoint={`/managers-hub/team/${teamId}/attendances`}
                  statsEndpoint={`/managers-hub/team/${teamId}/attendance-stats`}
                  subTitle={`Attendance for employees in the "${team.name}" team`}
                  getRequest={getRequest}
                />
              )}
            </Tab>
            <Tab
              eventKey="attendance_requests"
              title="Out of Perimeter Requests"
            >
              <Card className={"mb-5"}>
                <Card.Header className={"py-4"}>
                  <Card.Title as={"h4"} className={"mb-3"}>
                    Out of Perimeter Attendance Requests
                  </Card.Title>
                  <Card.Subtitle>
                    Employees that need to clock in / out outside their
                    designated work location will need to submit an "Out of
                    Perimeter" request. These requests will be listed here and
                    the teams supervisor will need to approve them before they
                    are counted as a valid clock in / out.
                  </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                  <LoadingView
                    isLoading={secondLoader}
                    fullHeight={false}
                    view={
                      <div>
                        <OutOfPerimeterAttendanceRequests
                          requests={attendanceRequests}
                          onActionClick={
                            !team.is_direct_supervisor
                              ? null
                              : (request, action) => {
                                  showAppModal({
                                    title: `You are about to set this request by ${
                                      request.employee.full_name
                                    } to "${action.toUpperCase()}"`,
                                    component: (
                                      <div>
                                        <p>Are you sure you want to proceed?</p>
                                      </div>
                                    ),
                                    truthyFunction: () => {
                                      attendanceRequestsAction(
                                        request.id,
                                        action
                                      );
                                    },
                                  });
                                }
                          }
                        />
                      </div>
                    }
                  />
                </Card.Body>
              </Card>
            </Tab>
          </Tabs>
        </>
      }
    />
  );
}

function SupervisorTeamViewPage() {
  return <DashboardPageContainer PageComponent={SupervisorTeamView} />;
}
export default SupervisorTeamViewPage;
