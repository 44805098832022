import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AppConstants } from "../appConstants";
import React from "react";
import { isEmpty, toTitleCase } from "../functions";
import { Loader, LoadingView } from "../components";

export const AppPieChart = ({
  data,
  nameKey = "name",
  valueKey = "count",
  chartHeight = 400,
}) => {
  return (
    <>
      {isEmpty(data) && <Loader text={"Fetching Data..."} spinnerText={""} />}
      {!isEmpty(data) && (
        <ResponsiveContainer width="100%" height={chartHeight}>
          <PieChart>
            <Pie
              data={data}
              labelLine={false}
              fill="#8884d8"
              nameKey={nameKey}
              dataKey={valueKey}
              label={({ name, percent }) =>
                `${name} ${(percent * 100).toFixed(2)}%`
              }
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    AppConstants.chartColors[
                      index % AppConstants.chartColors.length
                    ]
                  }
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export const AppBarChart = ({
  data,
  xName,
  xDataKey = "name",
  yDataKey = "count",
}) => {
  return (
    <>
      {isEmpty(data) && <Loader text={"Fetching Data..."} spinnerText={""} />}
      {!isEmpty(data) && (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data}>
            <XAxis dataKey={xDataKey} />
            <YAxis dataKey={yDataKey} />
            <Tooltip />
            <Legend />
            <Bar
              dataKey={yDataKey}
              name={xName || toTitleCase(xDataKey)}
              fill={AppConstants.primaryColor}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
