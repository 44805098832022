import React, { createContext, useEffect, useState } from "react";
import { AppRoutes } from "../appRoutes";
import { BsBook, BsHouse, BsWallet } from "react-icons/bs";
import {
  FaCog,
  FaCogs,
  FaSitemap,
  FaUserPlus,
  FaUsers,
  FaUsersCog,
  FaUserTie,
} from "react-icons/fa";
import { FaFolderTree } from "react-icons/fa6";
import { StaffPermissions } from "../../enums/staffEnums";

export const AppContext = createContext();

const initState = {
  authenticated: undefined,
  employee: {},
  organization: {},
  permissions: {},
  formData: {},
  targetPath: null,
  navLinks: [],
  adminOrganization: null,
  staffPermissions: null,
  labelsOverride: {},
  isMobileApp: localStorage.getItem("isMobileApp") === "true",
};

const AppContextProvider = ({ children }) => {
  const [contextState, setContextState] = useState(initState);

  const updateContextState = (updatedState) => {
    setContextState({ ...contextState, ...updatedState });
  };

  const hasStaffPermission = (permission) => {
    if (!contextState.staffPermissions) return false;
    return contextState.staffPermissions.includes(permission);
  };

  const hasAnyStaffPermission = (permissions) => {
    // permissions should be a list
    if (!contextState.staffPermissions) return false;
    return permissions.some((permission) =>
      contextState.staffPermissions.includes(permission)
    );
  };

  useEffect(() => {
    const permissions = contextState.permissions || {};
    const navLinks = [
      {
        name: "Home",
        path: AppRoutes.Home.path,
        icon: BsHouse,
        permission: null,
      },
      {
        name: "Organogram",
        path: AppRoutes.OrgChart.path,
        icon: FaSitemap,
        permission: null,
        alwaysShow: true,
      },
      {
        name: "Organization Tree",
        path: AppRoutes.OrgTree.path,
        icon: FaFolderTree,
        permission: null,
        alwaysShow: true,
      },
      {
        name: "Finance",
        path: AppRoutes.Finance.path,
        icon: BsWallet,
        permission: null,
      },
      {
        name: "Payroll",
        path: AppRoutes.Payroll.path,
        icon: BsBook,
        permission: null,
      },
      {
        name: "People",
        path: AppRoutes.People.path,
        icon: FaUsers,
        permission: null,
        expandTabs: false,
        tabs: {
          overview: { hasPermission: permissions.VIEW_EMPLOYEES_STATS },
          people: {
            hasPermission: permissions.VIEW_EMPLOYEE_DATA,
          },
          teams: {
            hasPermission: permissions.VIEW_TEAMS || permissions.MANAGE_TEAMS,
            otherName: contextState?.labelsOverride?.teams,
          },
          positions: {
            hasPermission:
              permissions.MANAGE_POSITIONS || permissions.VIEW_POSITIONS,
          },
          levels: {
            hasPermission: permissions.MANAGE_LEVELS || permissions.VIEW_LEVELS,
          },
          time_off_policies: {
            hasPermission:
              permissions.MANAGE_TIME_OFF_POLICIES ||
              permissions.VIEW_TIME_OFF_POLICIES,
          },
          shifts: {
            hasPermission: permissions.MANAGE_SHIFTS || permissions.VIEW_SHIFTS,
          },
          schedules: {
            hasPermission:
              permissions.MANAGE_SCHEDULES || permissions.VIEW_SCHEDULES,
          },
          announcements: {
            hasPermission:
              permissions.MANAGE_ANNOUNCEMENTS ||
              permissions.VIEW_ANNOUNCEMENTS,
          },
          attendance: {
            hasPermission:
              permissions.VIEW_ATTENDANCE || permissions.MANAGE_ATTENDANCE,
          },
          activities: {
            hasPermission:
              permissions.VIEW_EMPLOYEES_ACTIVITIES ||
              permissions.MANAGE_EMPLOYEES_ACTIVITIES,
          },
        },
      },
      {
        name: "Managers Hub",
        path: AppRoutes.ManagersHub.path,
        icon: FaUsersCog,
        permission: null,
      },
      {
        name: "Recruitment",
        path: AppRoutes.Recruitment.path,
        icon: FaUserPlus,
        permission: null,
      },
      {
        name: "My Profile",
        path: AppRoutes.MyProfile.path,
        icon: FaUserTie,
        permission: null,
      },
      {
        name: "Admin",
        path: AppRoutes.Admin.path,
        icon: FaCog,
        permission: null,
        expandTabs: false,
        tabs: {
          organization: {
            hasPermission:
              permissions.VIEW_ORGANIZATION_INFO ||
              permissions.MANAGE_ORGANIZATION_INFO,
          },
          permission_group: {
            hasPermission:
              permissions.VIEW_PERMISSIONS || permissions.MANAGE_PERMISSIONS,
          },
          departments: {
            hasPermission:
              permissions.VIEW_DEPARTMENTS || permissions.MANAGE_DEPARTMENTS,
            otherName: contextState?.labelsOverride?.departments,
          },
          branches: {
            hasPermission:
              permissions.VIEW_BRANCHES || permissions.MANAGE_BRANCHES,
          },
        },
      },
    ];

    if (contextState.adminOrganization) {
      navLinks.push({
        name: "Humnce Admin Hub",
        path: AppRoutes.Staff.path,
        icon: FaCogs,
        permission: null,
        alwaysShow: true,
        tabs: {
          organizations: {
            hasPermission: hasAnyStaffPermission([
              StaffPermissions.VIEW_ORGANIZATIONS,
              StaffPermissions.MANAGE_ORGANIZATIONS_SUBSCRIPTIONS,
              StaffPermissions.VIEW_ORGANIZATIONS,
            ]),
          },
          staff: {
            hasPermission: hasAnyStaffPermission([
              StaffPermissions.VIEW_STAFF,
              StaffPermissions.MANAGE_STAFF,
            ]),
          },
        },
      });
    }

    updateContextState({
      navLinks: navLinks,
    });
  }, [
    contextState.permissions,
    contextState.adminOrganization,
    contextState.staffPermissions,
  ]);

  const logOut = () => {
    setContextState(initState);
  };

  return (
    <AppContext.Provider
      value={{
        contextState,
        updateContextState,
        setContextState,
        logOut,
        hasStaffPermission,
        hasAnyStaffPermission,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
