import React from "react";

function EmployeeBasicHighlight({ employee }) {
  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className={"d-flex flex-row align-items-center"}>
          <img
            src={employee.profile_image}
            alt={employee.full_name}
            width={50}
            height={50}
            className="rounded-circle me-3"
          />
          <div className={"text-start"}>
            {employee.full_name}
            <div className="small mt-2">
              {employee?.position?.title || "No Position"} - (
              {employee.employee_number})
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeBasicHighlight;
