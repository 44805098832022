import React, { useContext, useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import EmployeeSearch from "../components/EmployeeSearch";
import { EmployeeList } from "../components/EmployeeCards";
import { updateObjectState } from "../../utils/functions";
import { AppContext } from "../../utils/components/AppContext";

const ENTIRE_ORGANIZATION = "Entire Organization";
const DEPARTMENTS = "Departments";
const BRANCHES = "Branches";
const TEAMS = "Teams";
const EMPLOYEE_TYPES = "Employee Types";
const SELECT_EMPLOYEES = "Select Employees";

const DEPARTMENT_IDS = "department_ids";
const BRANCH_IDS = "branch_ids";
const TEAM_IDS = "team_ids";
const EMPLOYEE_TYPE = "employee_type";
const EMPLOYEE_IDS = "employee_ids";

function EntitySelection({
  configs,
  formState,
  setFormState,
  requiredFields,
  setRequiredFields,
}) {
  const { contextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  const [originalRequiredFields, setOriginalRequiredFields] = useState([
    ...requiredFields,
    "entity_ids",
  ]);
  const [excludedEmployees, setExcludedEmployees] = useState([]);
  const [addedEmployees, setAddedEmployees] = useState([]);
  const [entityType, setEntityType] = useState(formState.entity_type);

  useEffect(() => {
    if (formState.entity_type === SELECT_EMPLOYEES) {
      setFormState((prevState) => ({
        ...prevState,
        disable: formState.employee_ids.length === 0,
      }));
    }
  }, [formState.entity_type, formState.employee_ids]);

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      entity_ids: [],
      excluded_employee_ids: [],
    }));
  }, []);

  const updateRequirement = (key, value = null) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setRequiredFields([...originalRequiredFields, key]);
  };

  const handleEntityTypeChange = (event) => {
    const { value } = event.target;
    setEntityType(value);
    setFormState((prevState) => ({
      ...prevState,
      entity_type: value,
      entity_ids: value === ENTIRE_ORGANIZATION ? [value] : [],
    }));

    if (value === DEPARTMENTS) {
      updateRequirement(DEPARTMENT_IDS);
    } else if (value === BRANCHES) {
      updateRequirement(BRANCH_IDS);
    } else if (value === TEAMS) {
      updateRequirement(TEAM_IDS);
    } else if (value === EMPLOYEE_TYPES) {
      updateRequirement(EMPLOYEE_TYPE);
    } else if (value === SELECT_EMPLOYEES) {
      updateObjectState(setFormState, { excluded_employee_ids: [] });
      setExcludedEmployees([]);
      updateRequirement(EMPLOYEE_IDS, []);
    } else {
      setRequiredFields(originalRequiredFields);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      // entity_id: value,
    }));
  };

  const SelectionItem = ({ name, value, idsKey }) => {
    return (
      <Form.Check
        type="checkbox"
        label={name}
        name={name}
        value={value || ""}
        checked={formState.entity_ids?.includes(value) || false}
        onChange={(e) => {
          const isChecked = e.target.checked;
          const entity_ids = isChecked
            ? [...formState.entity_ids, e.target.value]
            : formState.entity_ids.filter((id) => id !== e.target.value);

          setFormState((prevState) => ({
            ...prevState,
            entity_ids: entity_ids,
            [idsKey]: true,
          }));
        }}
      />
    );
  };

  const altEntityNames = {
    Departments: labelsOverride.departments,
    Teams: labelsOverride.teams,
  };

  return (
    <div>
      <h6 className={"mb-3"}>
        Please select the entity type and entities to apply this to
      </h6>
      <Alert variant={"warning"}>
        NOTE:
        <br />
        Only active employees that have been assigned positions in this
        organization will be affected by this selection.
      </Alert>
      <Form.Group controlId="entityType" className={"mb-4"}>
        <Form.Label>
          Entity Type<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          as="select"
          name="entity_type"
          value={entityType}
          onChange={handleEntityTypeChange}
        >
          <option value="">Select an entity type</option>
          {configs.entity_selections.map((type) => (
            <option key={type} value={type}>
              {altEntityNames[type] || type}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {requiredFields.includes(DEPARTMENT_IDS) && (
        <Form.Group controlId="teamDepartment" className={"mb-4"}>
          <Form.Label>
            Select {labelsOverride.departments || "Departments"}*
          </Form.Label>
          {configs.departments.map((department, index) => {
            return (
              <SelectionItem
                name={department.name}
                value={department.id}
                idsKey={DEPARTMENT_IDS}
                key={index}
              />
            );
          })}
        </Form.Group>
      )}

      {requiredFields.includes(BRANCH_IDS) && (
        <Form.Group controlId="teamBranch" className={"mb-4"}>
          <Form.Label>Select Branches*</Form.Label>
          {configs.branches.map((branch, index) => {
            return (
              <SelectionItem
                name={branch.name}
                value={branch.id}
                idsKey={BRANCH_IDS}
                key={index}
              />
            );
          })}
        </Form.Group>
      )}

      {requiredFields.includes(TEAM_IDS) && (
        <Form.Group controlId="teamTeam" className={"mb-4"}>
          <Form.Label>Select {labelsOverride.teams || "Teams"} *</Form.Label>
          {configs.teams.map((team, index) => {
            return (
              <SelectionItem
                name={team.name}
                value={team.id}
                idsKey={TEAM_IDS}
                key={index}
              />
            );
          })}
        </Form.Group>
      )}

      {requiredFields.includes(EMPLOYEE_TYPE) && (
        <Form.Group controlId="teamEmployeeType" className={"mb-4"}>
          <Form.Label>Employee Type *</Form.Label>
          {configs.employee_types.map((type, index) => {
            return (
              <SelectionItem
                name={type}
                value={type}
                idsKey={EMPLOYEE_TYPE}
                key={index}
              />
            );
          })}
        </Form.Group>
      )}

      {requiredFields.includes(EMPLOYEE_IDS) && (
        <div>
          <EmployeeSearch
            onResultClick={(employee) => {
              if (formState.entity_ids.includes(employee.id)) {
                return;
              }
              const employees = [employee, ...addedEmployees];
              setAddedEmployees(employees);
              setFormState((prevState) => ({
                ...prevState,
                entity_ids: [employee.id, ...prevState.entity_ids],
                [EMPLOYEE_IDS]: true,
              }));
            }}
          />
          {formState.employee_ids.length === 0 && (
            <Alert variant={"danger"}>
              Please select at least one mployee.
            </Alert>
          )}

          <EmployeeList
            employees={addedEmployees}
            add={false}
            action={(employee) => {
              const employees = [...addedEmployees];
              employees.splice(employees.indexOf(employee), 1);
              setAddedEmployees(employees);
              setFormState((prevState) => ({
                ...prevState,
                entity_ids: [
                  ...prevState.entity_ids.filter((id) => id !== employee.id),
                ],
              }));
            }}
          />
        </div>
      )}

      {!requiredFields.includes(EMPLOYEE_IDS) && (
        <div className={"mt-4"}>
          <Alert variant={"info"}>
            [Optional] Use the search box to add employees you want to exclude
            from this entity selection below
          </Alert>
          <EmployeeSearch
            onResultClick={(employee) => {
              if (formState.excluded_employee_ids.includes(employee.id)) {
                return;
              }
              const employees = [employee, ...excludedEmployees];
              setExcludedEmployees(employees);
              setFormState((prevState) => ({
                ...prevState,
                excluded_employee_ids: [
                  employee.id,
                  ...prevState.excluded_employee_ids,
                ],
              }));
            }}
          />

          <EmployeeList
            employees={excludedEmployees}
            add={false}
            action={(employee) => {
              const employees = [...excludedEmployees];
              employees.splice(employees.indexOf(employee), 1);
              setExcludedEmployees(employees);
              setFormState((prevState) => ({
                ...prevState,
                excluded_employee_ids: [
                  ...prevState.excluded_employee_ids.filter(
                    (id) => id !== employee.id
                  ),
                ],
              }));
            }}
          />
        </div>
      )}
    </div>
  );
}

export default EntitySelection;
