import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";
import {
  Card,
  Col,
  Popover,
  Row,
  Image,
  ListGroup,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import {
  FaPhone,
  FaEnvelope,
  FaCalendar,
  FaUser,
  FaPlus,
  FaWindowClose,
  FaChevronRight,
  FaExternalLinkAlt,
} from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { formDate } from "../../utils/dateTime";
import { AppContext } from "../../utils/components/AppContext";
import { AppRoutes } from "../../utils/appRoutes";

export const EmployeeLargeCard = ({ employee }) => {
  const { contextState, updateContextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  return (
    <Card className={"rounded-3"}>
      <Card.Header className={"text-center py-5"}>
        <Image
          fluid={true}
          src={employee.profile_image}
          alt={employee.full_name}
          className={"rounded-circle mx-auto"}
          height={"auto"}
          width={100}
        />
        <Card.Title className="fs-2 my-3">{employee.full_name}</Card.Title>
        <Card.Subtitle>{`${
          labelsOverride.employee_number || "Employee Number"
        }: ${employee.employee_number}`}</Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <div className={"p-4"}>
          <Row className="my-4">
            {employee.position && (
              <Col className="d-flex align-items-center my-3">
                <MdWork className="text-primary me-2" size={35} />
                <Card.Text className="fs-4">
                  {employee.position.title}
                </Card.Text>
              </Col>
            )}
            {employee.start_date && (
              <Col className="d-flex align-items-center my-3">
                <FaCalendar className="text-primary me-2" size={35} />
                <Card.Text className="fs-4">
                  {formDate(employee.start_date)}
                </Card.Text>
              </Col>
            )}
            {employee.work_phone && (
              <Col className="d-flex align-items-center my-3">
                <FaPhone className="text-primary me-2" size={35} />
                <Card.Text className="fs-4">{employee.work_phone}</Card.Text>
              </Col>
            )}
            {employee.work_email && (
              <Col className="d-flex align-items-center my-3">
                <FaEnvelope className="text-primary me-2" size={35} />
                <Card.Text className="fs-4">{employee.work_email}</Card.Text>
              </Col>
            )}
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export function EmployeeList({
  employees,
  action,
  add = true,
  showIcon = true,
}) {
  return (
    <ListGroup>
      {employees?.map((employee, i) => {
        return (
          <ListGroup.Item key={i} className={"mb-3 border-2"}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={employee.profile_image}
                  alt={employee.full_name}
                  width={40}
                  height={40}
                  className="rounded-circle me-2"
                />
                <div>
                  {employee.full_name} ({employee.employee_number})
                  <div className="small">{employee?.position?.title}</div>
                </div>
              </div>
              {action && !employee.no_action && (
                <div
                  className={"pointer"}
                  onClick={() => {
                    action(employee, i);
                  }}
                >
                  {add && showIcon ? (
                    <FaPlus />
                  ) : !add && showIcon ? (
                    <AiOutlineClose />
                  ) : (
                    <FaChevronRight />
                  )}
                </div>
              )}
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

export const EmployeePopover = React.forwardRef(
  ({ employee, ...props }, ref) => {
    const { contextState } = useContext(AppContext);
    const { permissions } = contextState;

    return (
      <Popover ref={ref} {...props}>
        <Popover.Header className="d-flex flex-column align-items-center">
          <Image
            src={employee.profile_image}
            roundedCircle
            className="mb-3"
            fluid
            height={50}
            width={50}
          />
          <h6 className={"text-center"}>
            {employee.full_name}
            <br /># {employee.employee_number}
          </h6>
        </Popover.Header>
        <Popover.Body>
          {employee.work_phone && (
            <p>
              <FaPhone className="me-2 text-primary" />
              {employee.work_phone}
            </p>
          )}
          {employee.work_email && (
            <p>
              <FaEnvelope className="me-2 text-primary" />
              {employee.work_email}
            </p>
          )}
          {employee.position && (
            <p>
              <FaUser className="me-2 text-primary" />
              {employee.position.title}
            </p>
          )}
          {employee.start_date && (
            <p>
              <FaCalendar className="me-2 text-primary" />
              {formDate(employee.start_date)}
            </p>
          )}
          {permissions.VIEW_EMPLOYEE_DATA && (
            <Button
              className={"mt-3"}
              variant={"link"}
              onClick={() => {
                window.open(
                  `${AppRoutes.Employee.paramLessPath}${employee.id}`,
                  "_blank"
                );
              }}
            >
              View Profile
              <FaExternalLinkAlt className={"ms-2"} />
            </Button>
          )}
        </Popover.Body>
      </Popover>
    );
  }
);

export const EmployeePopOverButtonLink = ({
  employee,
  popoverPosition = "right",
}) => {
  return (
    <OverlayTrigger
      key={employee.id}
      trigger="click"
      placement={popoverPosition}
      overlay={<EmployeePopover employee={employee} />}
      rootClose
    >
      <Button variant="link" className="">
        {employee.full_name}
      </Button>
    </OverlayTrigger>
  );
};
